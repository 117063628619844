import { AlertColor, SnackbarOrigin } from "@mui/material";
import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { BrandSlice } from "core/brand/entities/Brand";
import { City } from "core/city/entities/City";
import { Country } from "core/country/entities/Country";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import { StationType } from "core/station/entities/Station";
import { Store } from "core/stores/entities/Store";

export interface Alert {
  show?: boolean;
  title?: string | null;
  message?: string | null;
  duration?: number | null;
  severity?: AlertColor;
  position?: SnackbarOrigin;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  audioOn?: boolean | null;
}

export interface KitchensState {
  data: KitchenSlice[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface CitiesState {
  data: City[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface PlatformsState {
  data: string[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface CountriesState {
  data: Country[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface BrandsState {
  data: BrandSlice[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface StationTypesState {
  data: StationType[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface GlobalState {
  alert: Alert;
  title: string | null;
  kitchens: KitchensState;
  platforms: PlatformsState;
  countries: CountriesState;
  stationTypes: StationTypesState;
  brands: BrandsState;
  cities: CitiesState;
  canSearchOnTopBar: boolean;
  textSearchOnTopBar: string;
}

export const defaultData = {
  data: [],
  status: "idle",
  error: null,
};

export const initialState: GlobalState = {
  alert: { show: false },
  kitchens: defaultData as KitchensState,
  platforms: defaultData as PlatformsState,
  cities: defaultData as CitiesState,
  countries: defaultData as CountriesState,
  brands: defaultData as BrandsState,
  stationTypes: defaultData as StationTypesState,
  title: null,
  canSearchOnTopBar: false,
  textSearchOnTopBar: "",
};

export interface StoresState {
  data: Store[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}
