import {
  LinearProgress,
  Grid,
  TextField,
  FormControl,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { commons } from "app/i18n/types";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Coords } from "core/kitchens/entities/Kitchen";
import {
  CoordStr,
  defaultCoordStr,
  fromCoordsToString,
  fromStringToCoord,
  handlePolygonValidations,
} from "../utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useModal } from "app/hooks/useModal";
import { handleModalIcon } from "utils/modal";
import { useMount } from "react-use";

interface Props {
  platform: string;
  formState: Record<string, Coords>;
  setFormState: (value: SetStateAction<Record<string, Coords>>) => void;
  onError: (platform: string, error: boolean) => void;
}

const PolygonCard = (props: Props): React.ReactElement => {
  const { platform, formState, setFormState, onError } = props;
  const { t } = useTranslation();
  const modal = useModal();

  const [coords, setCoords] = useState<CoordStr>(defaultCoordStr);

  useMount(() => {
    if (!formState) return;
    const current = formState[platform];
    setCoords({
      high: fromCoordsToString(current.high),
      low: fromCoordsToString(current.low),
      medium: fromCoordsToString(current.medium),
    });
  });

  useEffect(() => {
    if (!formState) return;
    const current = formState[platform];
    modal.validator(handlePolygonValidations, {
      ...current,
      lng: current.location?.lng ?? null,
      lat: current.location?.lat ?? null,
    });
  }, [formState, platform]);

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        high: fromStringToCoord(coords.high),
        medium: fromStringToCoord(coords.medium),
        low: fromStringToCoord(coords.low),
      },
    }));
  }, [coords]);

  useEffect(() => {
    const error = !!Object.keys(modal.errors).length;
    onError(platform, error);
  }, [modal.errors]);

  const remove = () => {
    onError(platform, false);
    const tmp = { ...formState };
    delete tmp[platform];
    setFormState(tmp);
  };

  if (!formState) {
    return <LinearProgress />;
  }

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">
          {t(commons.POLYGONS)} - {platform}
        </Typography>
        <IconButton onClick={remove}>
          <DeleteOutlineOutlinedIcon color="error" />
        </IconButton>
      </Grid>
      <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">- {t(commons.LOCATION)}</Typography>
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth>
          <TextField
            label={t(commons.LONGITUDE)}
            type="number"
            value={formState[platform].location?.lng ?? null}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["lng"]),
            }}
            onChange={(event) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                [platform]: {
                  ...prev[platform],
                  location: {
                    lat: prev[platform].location?.lat ?? 0,
                    lng: Number(newValue),
                  },
                },
              }));
            }}
            autoComplete="off"
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <FormControl fullWidth>
          <TextField
            label={t(commons.LATITUDE)}
            type="number"
            value={formState[platform].location?.lat ?? null}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["lat"]),
            }}
            onChange={(event) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                [platform]: {
                  ...prev[platform],
                  location: {
                    lng: prev[platform].location?.lng ?? 0,
                    lat: Number(newValue),
                  },
                },
              }));
            }}
            autoComplete="off"
          />
        </FormControl>
      </Grid>
      <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">
          - {t(commons.COVERAGE)} {t(commons.HIGH)}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField
          value={coords.high}
          InputProps={{
            endAdornment: handleModalIcon(modal.errors["high"]),
          }}
          onChange={(event) => {
            const newValue = event.target.value;
            setCoords((prev) => ({
              ...prev,
              high: newValue,
            }));
          }}
          autoComplete="off"
          multiline
          fullWidth
          maxRows={2}
        />
      </Grid>
      <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">
          - {t(commons.COVERAGE)} {t(commons.MEDIUM)}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField
          value={coords.medium}
          InputProps={{
            endAdornment: handleModalIcon(modal.errors["medium"]),
          }}
          onChange={(event) => {
            const newValue = event.target.value;
            setCoords((prev) => ({
              ...prev,
              medium: newValue,
            }));
          }}
          autoComplete="off"
          multiline
          fullWidth
          maxRows={2}
        />
      </Grid>
      <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">
          - {t(commons.COVERAGE)} {t(commons.LOW)}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField
          value={coords.low}
          InputProps={{
            endAdornment: handleModalIcon(modal.errors["low"]),
          }}
          onChange={(event) => {
            const newValue = event.target.value;
            setCoords((prev) => ({
              ...prev,
              low: newValue,
            }));
          }}
          autoComplete="off"
          multiline
          fullWidth
          maxRows={2}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider variant="fullWidth" sx={{ borderColor: "#F1EFEF" }} />
      </Grid>
    </>
  );
};

export default PolygonCard;
