import {
  PedidosYaConfig,
} from "core/stores/entities/Store";
import { BaseModal } from "utils/modal";
import { commons } from "app/i18n/types";
import { object, string } from "yup";

export interface BaseDialogProps extends BaseModal {
  itemIds: string[];
}

export interface FormState {
  ids: string[];
  config: PedidosYaConfig;
}

export const defaultPedidosYaConfig: PedidosYaConfig = {
  chainId: "",
  remoteId: ""
}

export const handleValidations = object({
  chainId: string().required(commons.REQUIRED_FIELD),
  remoteId: string().required(commons.REQUIRED_FIELD),
});
