import { BaseModal } from "utils/modal";
import { commons } from "app/i18n/types";
import { object, string } from "yup";
import { dayOfTheWeek } from "utils/general";
import { KOSRowData } from "@foodology-co/alejandria";
import { StoreSchedule } from "core/stores/entities/Store";

export type ScheduleType = "week" | "theoreticalWeek";

export interface BaseDialogProps extends BaseModal {
  itemIds: string[];
  type: ScheduleType;
}

export interface FormState {
  ids: string[];
  type: ScheduleType;
  data: StoreSchedule;
}

export const defaultDataSchedule: StoreSchedule = {
  monday: [""],
  tuesday: [""],
  wednesday: [""],
  thursday: [""],
  friday: [""],
  saturday: [""],
  sunday: [""],
};

export const handleValidations = object({
  mondayStart: string().required(commons.REQUIRED_FIELD),
  mondayEnd: string().required(commons.REQUIRED_FIELD),
  tuesdayStart: string().required(commons.REQUIRED_FIELD),
  tuesdayEnd: string().required(commons.REQUIRED_FIELD),
  wednesdayStart: string().required(commons.REQUIRED_FIELD),
  wednesdayEnd: string().required(commons.REQUIRED_FIELD),
  thursdayStart: string().required(commons.REQUIRED_FIELD),
  thursdayEnd: string().required(commons.REQUIRED_FIELD),
  fridayStart: string().required(commons.REQUIRED_FIELD),
  fridayEnd: string().required(commons.REQUIRED_FIELD),
  saturdayStart: string().required(commons.REQUIRED_FIELD),
  saturdayEnd: string().required(commons.REQUIRED_FIELD),
  sundayStart: string().required(commons.REQUIRED_FIELD),
  sundayEnd: string().required(commons.REQUIRED_FIELD),
});

export const scheduleRefined = (schedule: StoreSchedule) => {
  const result: KOSRowData = {};
  const data = schedule as KOSRowData;
  dayOfTheWeek.forEach((day) => {
    const dayKey = day.toLowerCase();
    const times = data[dayKey]?.at(0)?.split("-");
    result[`${dayKey}Start`] = times?.at(0);
    result[`${dayKey}End`] = times?.at(1);
  });
  return result;
};
