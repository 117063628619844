import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Card, CardHeader, CardMedia, Grid } from "@mui/material";
import { useAlert } from "app/hooks/useAlert";
import { useAllStationTypes } from "app/hooks/useAllStationTypes";
import { commons, kitchenManager } from "app/i18n/types";
import {
  Station,
  StationResponse,
  StationType,
} from "core/station/entities/Station";
import { saveStationsByKitchen } from "core/station/repository/StationRepo";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BackdropLoading } from "utils/generalUI";

interface Props {
  kitchens: string[];
}

export const StationConfigSelector: FunctionComponent<Props> = (props) => {
  const { kitchens } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const useStationTypes = useAllStationTypes();
  const stationTypes = useStationTypes.list;
  const stationsByKichen = useStationTypes.stationsByKichen;

  const [stations, setStations] = useState<Station[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const selectStation = (station: StationType) => {
    const kitchenStations = [...stations];
    const index = kitchenStations.findIndex((s) => s.id === station.id);
    kitchenStations[index].active = !kitchenStations[index].active;
    setStations(kitchenStations);
  };

  const save = async () => {
    const request = {
      kitchens: kitchens,
      stationTypes: stations.map((station) => {
        return {
          ...station,
          active: station.active ? station.active : false,
        };
      }),
    };
    setLoading(true);
    await saveStationsByKitchen(request);
    setLoading(false);
    alert.successWithMsg({ title: t(kitchenManager.LINK_STATION_SUCCESS) });
  };

  const getData = (types: StationType[], activeStations: StationResponse[]) => {
    const actives = activeStations.map((el) => el.stationType.id);
    setStations(
      types.map(
        (type) =>
          ({
            ...type,
            active: actives.includes(type.id),
          } as Station)
      )
    );
  };

  useEffect(() => {
    getData(stationTypes, stationsByKichen);
  }, [stationTypes, stationsByKichen]);

  useEffect(() => {
    if (kitchens.length === 1) {
      useStationTypes.getStationsByKitchen(kitchens[0]);
    }
  }, [kitchens.at(0)]);

  return (
    <Grid container spacing={2}>
      <BackdropLoading open={loading} />

      {stations.map((station) => (
        <Grid item xs={6} md={4} lg={3} key={station.id}>
          <Card
            onClick={() => selectStation(station)}
            sx={{
              cursor: "pointer",
              backgroundColor: station.active ? "#e3e3e3" : "white",
            }}
          >
            <CardHeader
              title={station.name}
              action={station.active && <CheckCircleOutlineIcon />}
            />
            <CardMedia
              component="img"
              height="194"
              image={station.logoUrl}
              alt={station.name}
            />
          </Card>
        </Grid>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
          width: "100%",
        }}
      >
        <Button
          onClick={save}
          variant="outlined"
          color="primary"
          startIcon={<SaveIcon />}
          sx={{ py: 1, px: 2, fontWeight: "600" }}
        >
          {t(commons.SAVE)}
        </Button>
      </Box>
    </Grid>
  );
};
