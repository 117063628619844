import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { commons } from "app/i18n/types";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleModalIcon } from "utils/modal";
import { UseModal } from "app/hooks/useModal";
import { KOSRowData } from "@foodology-co/alejandria";
import { StoreSchedule } from "core/stores/entities/Store";

interface Props {
  day: string;
  modal: UseModal;
  schedule: StoreSchedule;
  setSchedule: (form: StoreSchedule) => void;
}

export const HandleFormRow = (props: Props) => {
  const { day, schedule, setSchedule, modal } = props;
  const { t } = useTranslation();

  const [data, setData] = useState<KOSRowData>({});

  useEffect(() => {
    setData(schedule);
  }, [JSON.stringify(schedule)]);

  const dayKey = day.toLowerCase();
  const times = data[dayKey]?.at(0)?.split("-");

  return (
    <Fragment key={`Schedule-${dayKey}`}>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">{t(`daysOfWeek.${day}`)}</Typography>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            type="time"
            variant="outlined"
            label={t(commons.START)}
            value={times?.at(0) ?? null}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors[`${dayKey}Start`]),
            }}
            onChange={(event) => {
              const dataTmp: KOSRowData = { ...data };
              const newValue = event.target.value;
              dataTmp[dayKey] = [`${newValue}-${times?.at(1) ?? ""}`];
              setSchedule(dataTmp as StoreSchedule);
            }}
            focused
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            type="time"
            variant="outlined"
            label={t(commons.END)}
            value={times?.at(1) ?? null}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors[`${dayKey}End`]),
            }}
            onChange={(event) => {
              const dataTmp: KOSRowData = { ...data };
              const newValue = event.target.value;
              dataTmp[dayKey] = [`${times?.at(0) ?? ""}-${newValue}`];
              setSchedule(dataTmp as StoreSchedule);
            }}
            focused
          />
        </FormControl>
      </Grid>
    </Fragment>
  );
};
