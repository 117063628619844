import appConfig from "config/app";
import Cookies from "js-cookie";

export enum CookieItem {
  userId = "userId",
  loginToken = "loginToken",
  scope = "scope",
  kitchenId = "kitchenId",
  companyId = "companyId",
}

const getCookieConfig = () => {
  const env = appConfig.env;
  const cookieConfig = {
    domain: env === "development" ? "localhost" : ".foodology.com.co",
  };
  return { env, options: cookieConfig };
};

export const setCookie = (item: CookieItem, value: string): void => {
  const cookieConfig = getCookieConfig();
  Cookies.set(`${cookieConfig.env}.${item}`, value, cookieConfig.options);
};

export const getCookie = (item: CookieItem): string | undefined => {
  const cookieConfig = getCookieConfig();
  return Cookies.get(`${cookieConfig.env}.${item}`);
};

export const removeCookie = (item: CookieItem): void => {
  const cookieConfig = getCookieConfig();
  Cookies.remove(`${cookieConfig.env}.${item}`, cookieConfig.options);
};
