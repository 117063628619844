import { Alert, Grid } from "@mui/material";
import { alertMessage } from "app/i18n/types";
import { useTranslation } from "react-i18next";

export const SelectedItemAlert = (props: { items: string[] }) => {
  const { items } = props;
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Alert severity="info">
        {t(alertMessage.ROWS_SELECTED, { rows: items.length })}
      </Alert>
    </Grid>
  );
};
