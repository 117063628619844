import { KOSMenu } from "@foodology-co/alejandria";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { hideAlert } from "app/store/slices/global";
import { getUserMenu } from "app/store/slices/menu/thunks";
import appConfig from "config/app";
import { FunctionComponent, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useMount } from "react-use";
import textToSpeech from "utils/textToSpeech";
import TopBar from "../TopBar";
import { useAllKitchens } from "app/hooks/useAllKitchens";
import { useAllPlatforms } from "app/hooks/useAllPlatforms";
import { useAllCities } from "app/hooks/useAllCities";
import { useAllCountries } from "app/hooks/useAllCountries";
import { useAllBrands } from "app/hooks/useAllBrands";
import { useAllStationTypes } from "app/hooks/useAllStationTypes";

interface AppLayoutProps {}

const AppLayout: FunctionComponent<AppLayoutProps> = () => {
  const navigator = useNavigator();
  const dispatch = useAppDispatch();
  const allKitchens = useAllKitchens();
  const allPlatforms = useAllPlatforms();
  const allCities = useAllCities();
  const allCountries = useAllCountries();
  const allBrands = useAllBrands();
  const allStationTypes = useAllStationTypes();

  const menuOpen = useAppSelector((state) => state.menu.open);
  const menu = useAppSelector((state) => state.menu.items);
  const user = useAppSelector((state) => state.session.user.data);

  const alert = useAppSelector((state) => state.global.alert);

  const hideAppAlert = () => dispatch(hideAlert());

  useEffect(() => {
    const { message, audioOn } = alert;
    if (message && audioOn) {
      textToSpeech(message, 600);
    }
  }, [alert]);

  const handleCloseAlert = () => {
    if (hideAppAlert) {
      hideAppAlert();
    }
  };

  const onClickMenuItem = (to: string | undefined) => {
    const path = to!!;
    if (to!!.includes("http")) {
      window.location.href = path;
    } else if (path) {
      navigator.to(path);
    }
  };

  const onBackAction = () => {
    window.history.back();
  };

  useMount(() => {
    dispatch(getUserMenu());
    allBrands.get();
    allCountries.get();
    allCities.get();
    allKitchens.get();
    allPlatforms.get();
    allStationTypes.get();
  });

  return (
    <Box display="flex" pt={7}>
      <Snackbar
        open={alert.show}
        onClose={handleCloseAlert}
        autoHideDuration={alert.duration}
        anchorOrigin={alert.position}
      >
        <Alert
          variant="filled"
          severity={alert.severity}
          sx={{ minWidth: 360 }}
          onClose={handleCloseAlert}
          icon={alert.icon}
        >
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
          <Box>{alert.action}</Box>
        </Alert>
      </Snackbar>

      <TopBar />

      {menuOpen && (
        <KOSMenu
          user={user ? { ...user, rolName: user.roleName } : undefined}
          drawerOpen={menuOpen}
          items={menu}
          onClickMenuItem={onClickMenuItem}
          onBackAction={onBackAction}
          homeUrl={appConfig.kitchenDisplay.url ?? "/"}
          isBackButtonEnable
          sx={{ zIndex: 14 }}
        />
      )}
      <Box flexGrow={1} minHeight="calc(100vh - 56px)" overflow="auto">
        <Outlet />
      </Box>
    </Box>
  );
};

export default AppLayout;
