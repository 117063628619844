import { FunctionComponent, memo, useEffect, useState } from "react";
import KitchenSelectorAutoCompletes from "../Autocomplete";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import {
  getCitiesOptions,
  getLocationsOptionsByCity,
  getLocationsOptionsByCountry,
} from "utils/kitchen";
import { useAllCities } from "app/hooks/useAllCities";
import { useAllKitchens } from "app/hooks/useAllKitchens";
import { City } from "core/city/entities/City";
import { useAllCountries } from "app/hooks/useAllCountries";
import { Country } from "core/country/entities/Country";

interface Props {
  selected?: {
    country?: string;
    city?: string;
    location?: string;
  };
  onCountryChange?: (country?: Country) => void;
  onCityChange?: (city?: City) => void;
  onKitchenChange?: (kitchen?: KitchenSlice) => void;
  disabled?: boolean;
  size?: "small" | "medium";
  hideCountrySelector?: boolean;
  hideCitySelector?: boolean;
  hideLocationSelector?: boolean;
}

const KitchenSelector: FunctionComponent<Props> = (props) => {
  const {
    selected,
    onCountryChange,
    onCityChange,
    onKitchenChange,
    disabled,
    size,
    hideCountrySelector,
    hideCitySelector,
    hideLocationSelector,
  } = props;

  const useCountries = useAllCountries();
  const countries = useCountries.list;
  const useCities = useAllCities();
  const cities = useCities.list;
  const useKitchens = useAllKitchens();
  const kitchens = useKitchens.list;

  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [selectedCity, setSelectedCity] = useState<City>();
  const [selectedLocation, setSelectedLocation] = useState<KitchenSlice>();
  const [auto, setAuto] = useState<boolean>(false);

  useEffect(() => {
    if (selected?.country || selected?.city || selected?.location) {
      setAuto(true);
    }
  }, [selected]);

  useEffect(() => {
    if (!auto) return;
    setSelectedCountry(useCountries.byCode(selected?.country ?? ""));
    setSelectedCity(useCities.byCode(selected?.city ?? ""));
    setSelectedLocation(useKitchens.byKitchenId(selected?.location ?? ""));
  }, [auto]);

  useEffect(() => {
    if (auto) return;
    onCountryChange?.(selectedCountry);
    setSelectedCity(undefined);
    setSelectedLocation(undefined);
  }, [selectedCountry]);

  useEffect(() => {
    if (auto) return;
    onCityChange?.(selectedCity);
    setSelectedLocation(undefined);
  }, [selectedCity]);

  useEffect(() => {
    if (auto) return;
    onKitchenChange?.(selectedLocation);
  }, [selectedLocation]);

  const countriesOptionsTmp = !hideCountrySelector ? countries : [];
  const citiesOptionsTmp = !hideCitySelector
    ? getCitiesOptions(cities, selectedCountry)
    : [];
  let locationsOptionsTmp = !hideLocationSelector
    ? getLocationsOptionsByCity(kitchens, selectedCity)
    : [];
  if (hideCitySelector) {
    locationsOptionsTmp = getLocationsOptionsByCountry(
      kitchens,
      selectedCountry
    );
  }
  if (hideCountrySelector && hideCitySelector) {
    locationsOptionsTmp = kitchens;
  }

  return (
    <KitchenSelectorAutoCompletes
      countriesOptions={countriesOptionsTmp}
      citiesOptions={citiesOptionsTmp}
      locationsOptions={locationsOptionsTmp}
      selectedCountry={selectedCountry}
      setSelectedCountry={(selected) => {
        setAuto(false);
        setSelectedCountry(selected);
      }}
      selectedCity={selectedCity}
      setSelectedCity={(selected) => {
        setAuto(false);
        setSelectedCity(selected);
      }}
      selectedLocation={selectedLocation}
      setSelectedLocation={(selected) => {
        setAuto(false);
        setSelectedLocation(selected);
      }}
      disabled={disabled}
      size={size}
      hideCountrySelector={hideCountrySelector}
      hideCitySelector={hideCitySelector}
      hideLocationSelector={hideLocationSelector}
    />
  );
};

export default memo(KitchenSelector);
