import appConfig from "config/app";
import { ApiVersion, getHttp, postHttp } from "utils/http";
import { Kitchen, SetKitchenPolygonsPayload } from "../entities/Kitchen";

export const getAllKitchens = async (): Promise<Kitchen[]> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `kitchen/all`
    );
    const data = await response.json();
    return data.data as Kitchen[];
  } catch (ex: any) {
    console.error(`Error getting all kitchens | ${ex.message}`);
    return [] as Kitchen[];
  }
};

export const upsertKitchen = async (
  kitchen: Kitchen
): Promise<Kitchen | null> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      "kitchen/upsert",
      kitchen
    );
    const data = await response.json();
    return data as Kitchen;
  } catch (ex: any) {
    console.error(`Error upserting kitchen: ${ex.message}`);
    return null;
  }
};

export const getKitchen = async (id: string): Promise<Kitchen | undefined> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `kitchen/${id}`
    );
    const data = await response.json();
    return data.data as Kitchen;
  } catch (ex: any) {
    console.error(`Error in getKitchen | ${ex.message}`);
    return undefined;
  }
};

export const setKitchenPolygons = async (
  data: SetKitchenPolygonsPayload
): Promise<boolean> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      "kitchen/polygons/update",
      data
    );
    const result = (await response.text()) === "true";
    return result;
  } catch (ex: any) {
    console.error(`Error in setKitchenPolygons: ${ex.message}`);
    return false;
  }
};
