import {
  LinearProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Divider,
  Alert,
} from "@mui/material";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { commons } from "app/i18n/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Coords, Kitchen } from "core/kitchens/entities/Kitchen";
import { BaseDialogProps, defaultPolygon } from "../utils";
import { useAllPlatforms } from "app/hooks/useAllPlatforms";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import PolygonCard from "./Card";
import { setKitchenPolygons } from "core/kitchens/repository/kitchenRepo";

interface Props extends BaseDialogProps {
  kitchen?: Kitchen;
}

const HandleFormPolygons: React.FC<Props> = (props) => {
  const { onClose, kitchen: initialValues } = props;
  const { t } = useTranslation();
  const alert = useAlert();

  const [formState, setFormState] = useState<Record<string, Coords>>({});
  const platforms = useAllPlatforms().notIn(Object.keys(formState));
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string>();

  useEffect(() => {
    if (!initialValues?.polygons) return;
    setFormState({ ...initialValues.polygons });
  }, [initialValues]);

  const addPolygonPlatform = (platform: string) => {
    if (!platform) return;
    if (formState[platform]) return;
    setFormState((prev) => ({
      ...prev,
      [platform]: defaultPolygon,
    }));
    setSelectedPlatform(undefined);
  };

  const onError = (platform: string, error: boolean) => {
    if (error) {
      setErrors((prev) => ({ ...prev, [platform]: error }));
    } else {
      const tmp = { ...errors };
      delete tmp[platform];
      setErrors(tmp);
    }
  };

  const getResponse = (polygons: Record<string, Coords>) => {
    return setKitchenPolygons({ polygons, id: initialValues?.id ?? "-0" });
  };

  const handleSubmit = useCallback(() => {
    if (!!Object.keys(errors).length) {
      return;
    }
    setIsLoading(true);
    getResponse(formState)
      .then(() => {
        alert.success();
        onClose();
      })
      .catch(() => {
        alert.error();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formState, errors]);

  if (!formState) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={8}>
        <FormControl fullWidth>
          <InputLabel>{t(commons.PLATFORM)}</InputLabel>
          <Select
            label={t(commons.PLATFORM)}
            value={selectedPlatform ?? ""}
            onChange={(event: SelectChangeEvent<string>) => {
              const newValue = event.target.value;
              setSelectedPlatform(newValue);
            }}
          >
            {platforms.map((option) => (
              <MenuItem key={`Platform-${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="outlined"
          startIcon={<AddLocationAltOutlinedIcon />}
          sx={{ minHeight: "100%" }}
          disabled={!selectedPlatform}
          onClick={() => addPolygonPlatform(selectedPlatform ?? "")}
          fullWidth
        >
          {t(commons.ADD)}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {Object.keys(formState).map((platform) => (
        <PolygonCard
          key={`PolygonCard${platform}`}
          platform={platform}
          formState={formState}
          setFormState={setFormState}
          onError={onError}
        />
      ))}

      {!Object.keys(formState).length && (
        <Grid item xs={12}>
          <Alert children={t(commons.TABLE_EMPTY)} severity="info" />
        </Grid>
      )}

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(errors).length,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default HandleFormPolygons;
