import { KitchenModal } from "utils/modal";
import { commons } from "app/i18n/types";
import { array, number, object, string } from "yup";
import { Coord, Coords, Kitchen, Status } from "core/kitchens/entities/Kitchen";

export interface BaseDialogProps extends KitchenModal {
  onSave: (editedKitchen: Kitchen) => Promise<void>;
  onSuccess: () => void;
}

export interface FormState extends Partial<Kitchen> {}

export const handleValidations = object({
  kitchenId: string().required(commons.REQUIRED_FIELD),
  name: string().required(commons.REQUIRED_FIELD),
  status: string()
    .oneOf([Status.ACTIVE, Status.INACTIVE])
    .required(commons.REQUIRED_FIELD),
  country: string().required(commons.REQUIRED_FIELD),
  city: object({
    _id: string().required(commons.REQUIRED_FIELD),
    code: string().required(commons.REQUIRED_FIELD),
    name: string().required(commons.REQUIRED_FIELD),
  }).required(commons.REQUIRED_FIELD),
  address: string().required(commons.REQUIRED_FIELD),
  type: string().required(commons.REQUIRED_FIELD),
  locationCode: string().required(commons.REQUIRED_FIELD),
});

export const defaultFormState: FormState = {
  kitchenId: "",
  name: "",
  status: Status.ACTIVE,
  tier: 0,
  protocols: [],
  address: "",
  activeOrders: {
    inStation: 0,
    total: 0,
    inWaitingForDelivery: 0,
    inPacking: 0,
    isLate: 0,
    isLateRT: 0,
  },
  country: "",
  billing: {},
  city: {
    code: "",
    name: "",
    _id: "",
  },
  peopleActive: 0,
  polygons: {},
  cityIdDidi: "",
  slug: "",
  latestTurnAt: new Date(),
  rappiAlliesPassword: undefined,
  rappiAlliesUser: undefined,
};

export interface CoordStr {
  low: string;
  medium: string;
  high: string;
}

export const defaultCoordStr: CoordStr = {
  low: "",
  medium: "",
  high: "",
};

export const defaultPolygon: Coords = {
  location: undefined,
  low: [],
  medium: [],
  high: [],
};

export const fromCoordsToString = (coords: Coord[]): string => {
  return coords.length ? JSON.stringify(coords) : "";
};

export const handlePolygonValidations = object({
  lng: number().required(commons.REQUIRED_FIELD),
  lat: number().required(commons.REQUIRED_FIELD),
  low: array().min(1).required(commons.REQUIRED_FIELD),
  medium: array().min(1).required(commons.REQUIRED_FIELD),
  high: array().min(1).required(commons.REQUIRED_FIELD),
});

export const fromStringToCoord = (coords: string): Coord[] => {
  try {
    return JSON.parse(coords) as Coord[];
  } catch (e) {
    return [];
  }
};
