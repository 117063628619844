import { Box } from "@mui/material";
import { modules } from "config/module";
import React from "react";
import { ObjectParams } from "./http";
import { TypeOfRole } from "./role";
import { getRoutePaths, refineLink } from "./route";

interface PageBoxProps {
  children: React.ReactNode;
}

export const PageBox = (props: PageBoxProps) => {
  const { children } = props;
  return (
    <Box
      height="calc(100vh - 56px)"
      overflow="auto"
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      gap={2}
      flexWrap={{ xs: "nowrap", md: "wrap" }}
    >
      <Box width="100%" p={2}>
        {children}
      </Box>
    </Box>
  );
};

export type Module = {
  moduleId: string;
  moduleName: string;
  description?: string;
  link?: string;
  locationType?: string;
  submodules?: Module[];
};

const getLink = (link?: string): string => {
  if (!link?.includes(":locationType")) {
    return `/:locationType${link}`;
  }
  return link ?? "";
};

export const refineModules = (role: TypeOfRole): Module[] => {
  if (!role) return [];
  const result: Module[] = [];
  const links = getRoutePaths([], role);
  modules.forEach((module: Module) => {
    const submodules: Module[] = [];
    module.submodules?.forEach((submodule: Module) => {
      const link = getLink(submodule.link);
      const validate = links.includes(link);
      if (validate) {
        submodules.push({ ...submodule, link });
      }
    });
    if (submodules.length) {
      result.push({ ...module, submodules });
    }
  });
  return result;
};

export const getModuleLink = (inventoryModule: string) => {
  const link = `/modules/${inventoryModule}`;
  switch (inventoryModule) {
    case "kitchen-inventory-management":
      return `${link}/:kitchenId`;
    case "production-center-inventory-management":
      return `${link}/:cpId`;
    default:
      return link;
  }
};

export const getNavs = (
  navs: Module[],
  role: TypeOfRole,
  location: string,
  params: ObjectParams,
  modules?: Module[],
  locationType?: string
): Module[] => {
  const modulesTmp = modules ?? refineModules(role);
  const pathname = location.replace(/%20/g, " ");
  let ended = false;

  modulesTmp.forEach((module) => {
    const paramsRefined = {
      ...(!params ? {} : params),
      locationType: locationType ?? module.locationType,
    } as ObjectParams;
    if (!!module.submodules?.length && !navs.length) {
      getNavs(
        navs,
        role,
        pathname,
        paramsRefined,
        module.submodules,
        paramsRefined["locationType"]
      );
    }
    const link = module.link
      ? refineLink(module.link, paramsRefined)
      : getModuleLink(module.moduleId);
    if (navs.length && !ended) {
      navs.push({ moduleId: "", moduleName: module.moduleName, link });
      ended = true;
    }
    if (pathname === link && !ended) {
      navs.push({ moduleId: "", moduleName: module.moduleName, link });
      ended = true;
    }
  });

  return modules ? navs : navs.reverse();
};

export interface ModuleResume {
  titles: string[];
  path: string;
}

export const getModuleResume = (): ModuleResume[] => {
  const result: ModuleResume[] = [];
  modules.forEach((module) => {
    module.submodules?.forEach((submodule) => {
      result.push({
        titles: [module.moduleName, submodule.moduleName],
        path: submodule.link ?? "",
      });
    });
  });
  return result;
};
