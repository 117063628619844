import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDialogTitle } from "utils/modal";
import { BaseDialogProps } from "./utils";
import { HandleForm } from "./form";
import { useAllStore } from "app/hooks/useAllStore";

const PedidosYaDialog = (props: BaseDialogProps) => {
  const { onClose, action, itemIds, title, open } = props;

  const store = useAllStore();
  const { t } = useTranslation();
  const dialogTitle = getDialogTitle(t, title, action);

  const [itemId, setItemId] = useState<string>();

  useEffect(() => {
    itemIds.length === 1 && setItemId(itemIds[0]);
  }, [itemIds]);

  useEffect(() => {
    itemId && store.findOne(itemId, "data=pedidosya_config");
  }, [itemId]);

  return (
    <ManagerDialog
      open={open}
      title={dialogTitle}
      onClose={onClose}
      size="xs"
      content={<HandleForm {...props} item={store.findOneData} />}
    />
  );
};

export default PedidosYaDialog;
