import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { commons } from "app/i18n/types";
import { City } from "core/city/entities/City";
import { Country } from "core/country/entities/Country";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import { allCity } from "utils/city";
import { allKitchens } from "utils/kitchen";

interface Props {
  countriesOptions: Country[];
  citiesOptions: City[];
  locationsOptions: KitchenSlice[];
  selectedCountry?: Country;
  selectedCity?: City;
  selectedLocation?: KitchenSlice;
  setSelectedCountry: (country?: Country) => void;
  setSelectedCity: (city?: City) => void;
  setSelectedLocation: (location?: KitchenSlice) => void;
  disabled?: boolean;
  size?: "small" | "medium";
  hideCountrySelector?: boolean;
  hideCitySelector?: boolean;
  hideLocationSelector?: boolean;
  allCityOrKitchens?: boolean;
}

const KitchenSelectorAutoCompletes: FunctionComponent<Props> = (props) => {
  const {
    countriesOptions,
    citiesOptions,
    locationsOptions,
    selectedCountry,
    selectedCity,
    selectedLocation,
    setSelectedCountry,
    setSelectedCity,
    setSelectedLocation,
    disabled,
    size,
    hideCountrySelector,
    hideCitySelector,
    hideLocationSelector,
    allCityOrKitchens,
  } = props;

  const { t } = useTranslation();

  const countriesOptionsTmp = [...countriesOptions];
  const citiesOptionsTmp = [...citiesOptions];
  const locationsOptionsTmp = [...locationsOptions];

  countriesOptionsTmp.sort((a, b) => a.code.localeCompare(b.code));
  citiesOptionsTmp.sort((a, b) => a.name.localeCompare(b.name));
  locationsOptionsTmp.sort((a, b) => a.name.localeCompare(b.name));

  if (allCityOrKitchens) {
    if (!!citiesOptionsTmp.length && !citiesOptionsTmp.includes(allCity)) {
      citiesOptionsTmp.unshift(allCity);
    }
    if (
      !!locationsOptionsTmp.length &&
      !locationsOptionsTmp.find((el) => el.kitchenId === allKitchens.kitchenId)
    ) {
      locationsOptionsTmp.unshift(allKitchens);
    }
  }

  const hideArray = [
    !!hideCountrySelector,
    !!hideCitySelector,
    !!hideLocationSelector,
  ];
  const hides = hideArray.filter((el) => !el).length;

  let smColumns = hides > 0 ? 12 / hides : 0;

  return (
    <Grid container spacing={2}>
      {!hideCountrySelector && (
        <Grid item sm={smColumns} xs={12}>
          <Autocomplete
            id="CountryAutocomplete"
            getOptionLabel={(option) => t(option.code).toUpperCase()}
            options={countriesOptionsTmp}
            value={selectedCountry ?? null}
            onChange={(_: any, newValue: Country | null) =>
              setSelectedCountry(newValue ?? undefined)
            }
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={t(commons.COUNTRY)} size={size} />
            )}
            disabled={disabled || countriesOptionsTmp.length === 0}
          />
        </Grid>
      )}
      {!hideCitySelector && (
        <Grid item sm={smColumns} xs={12}>
          <Autocomplete
            id="CityAutocomplete"
            options={citiesOptionsTmp}
            getOptionLabel={(option) => t(option.name).toUpperCase()}
            value={selectedCity ?? null}
            onChange={(_: any, newValue: City | null) => {
              setSelectedCity(newValue ?? undefined);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={t(commons.CITY)} size={size} />
            )}
            disabled={disabled || citiesOptions.length === 0}
          />
        </Grid>
      )}
      {!hideLocationSelector && (
        <Grid item sm={smColumns} xs={12}>
          <Autocomplete
            id="KitchenAutocomplete"
            options={locationsOptionsTmp}
            value={selectedLocation ?? null}
            getOptionLabel={(option) => t(option.name).toLocaleUpperCase()}
            onChange={(_: any, newValue: KitchenSlice | null) =>
              setSelectedLocation(newValue ?? undefined)
            }
            sx={{ width: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option.kitchenId === value.kitchenId
            }
            renderInput={(params) => (
              <TextField {...params} label={t(commons.LOCATION)} size={size} />
            )}
            disabled={disabled || locationsOptions.length === 0}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default memo(KitchenSelectorAutoCompletes);
