import appConfig from "config/app";
import { ApiVersion, getHttp, postHttp } from "utils/http";
import {
  CreateStationsrequest,
  KosResponse,
  StationResponse,
  StationType,
} from "../entities/Station";

export const allStationType = async (): Promise<StationType[]> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `station/type/all`
    );
    const result: KosResponse<StationType> = await response.json();
    return result.data;
  } catch (ex: any) {
    console.error(`Error allStationType | ${ex.message}`);
    throw ex;
  }
};

export const stationsByKitchen = async (
  kitchen: string
): Promise<StationResponse[]> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `station/kitchen/${kitchen}`
    );
    const result: KosResponse<StationResponse> = await response.json();
    return result.data;
  } catch (ex: any) {
    console.error(`Error stationsByKitchen | ${ex.message}`);
    throw ex;
  }
};

export const saveStationsByKitchen = async (
  request: CreateStationsrequest
): Promise<StationResponse[]> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `station/create`,
      request
    );
    const result: KosResponse<StationResponse> = await response.json();
    return result.data;
  } catch (ex: any) {
    console.error(`Error saveStationsByKitchen | ${ex.message}`);
    throw ex;
  }
};
