import appConfig from "config/app";
import { ApiVersion, getHttp, postHttp } from "utils/http";
import {
  AvailabilityPlannedPayload,
  AvailabilityRemotePayload,
  PaginatedStores,
  SetLegacySchedulePayload,
  Store,
  StoreQuery,
  UpdatePedidosYaPayload,
} from "../entities/Store";

export const storesByQuery = async (
  query: StoreQuery
): Promise<PaginatedStores> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `stores/query`,
      query
    );
    const result: PaginatedStores = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getPurchaseOrders | ${ex.message}`);
    throw ex;
  }
};

export const storeFindOne = async (
  id: string,
  extraParams?: string
): Promise<Store> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/${id}/get`,
      undefined,
      extraParams
    );
    const result = await response.json();
    return result.data as Store;
  } catch (ex: any) {
    console.error(`Error storeFindOne | ${ex.message}`);
    throw ex;
  }
};

export const storeAvailabilityPlanned = async (
  data: AvailabilityPlannedPayload
): Promise<boolean> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/availability/planned`,
      data
    );
    const result: boolean = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error storeAvailabilityPlanned | ${ex.message}`);
    throw ex;
  }
};

export const storeAvailabilityRemote = async (
  data: AvailabilityRemotePayload
): Promise<boolean> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/availability/remote`,
      data
    );
    const result: boolean = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error storeAvailabilityRemote | ${ex.message}`);
    throw ex;
  }
};

export const storeSetLegacySchedule = async (
  data: SetLegacySchedulePayload
): Promise<boolean> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/set/legacy/schedule`,
      data
    );
    const result: boolean = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error storeSetLegacySchedule | ${ex.message}`);
    throw ex;
  }
};

export const storeCreate = async (data: Partial<Store>): Promise<boolean> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/create`,
      data
    );
    const result: boolean = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error storeCreate | ${ex.message}`);
    throw ex;
  }
};

export const storeUpdate = async (data: Partial<Store>): Promise<boolean> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/update`,
      data
    );
    const result: boolean = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error storeUpdate | ${ex.message}`);
    throw ex;
  }
};

export const storePedidosYaUpdate = async (data: UpdatePedidosYaPayload): Promise<boolean> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/pedidos-ya/update`,
      data
    );
    const result: boolean = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error storeUpdate | ${ex.message}`);
    throw ex;
  }
};
