import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { commons } from "app/i18n/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleModalIcon } from "utils/modal";
import { BaseDialogProps, FormState, getAvailabilityPlanned, handleValidations } from "./utils";
import {
  AvailabilityPlannedPayload,
  reasonsOnOff,
  Store,
} from "core/stores/entities/Store";
import { useModal } from "app/hooks/useModal";
import { storeAvailabilityPlanned } from "core/stores/repository/storeRepo";
import { SelectedItemAlert } from "../Alerts";

interface Props extends BaseDialogProps {
  item?: Store;
}

export const HandleForm = (props: Props) => {
  const { item: initialValues, itemIds, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const modal = useModal();

  const toFormState = (): FormState => ({
    ids: itemIds,
    availability: initialValues?.availability,
    reason: "",
  });

  const [formState, setFormState] = useState<FormState>(() => toFormState());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");

  useEffect(() => {
    setFormState(() => toFormState());
  }, [initialValues]);

  useEffect(() => {
    modal.validator(handleValidations, { ...formState, action });
  }, [formState]);

  const getResponse = (data: FormState) => {
    return storeAvailabilityPlanned(data as AvailabilityPlannedPayload);
  };

  const handleSubmit = useCallback(() => {
    if (!handleValidations.isValidSync({ ...formState, action })) {
      return;
    }
    setIsLoading(true);
    getResponse(formState)
      .then(() => {
        alert.success();
        onSuccess();
        onClose();
      })
      .catch(() => {
        alert.error();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formState, props.action]);

  const reasons = (reasonsOnOff[action] ?? []) as string[];

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <SelectedItemAlert items={itemIds} />

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>{t(commons.ACTION)}</InputLabel>
          <Select
            label={t(commons.ACTION)}
            value={action}
            endAdornment={handleModalIcon(modal.errors["action"])}
            onChange={(event: SelectChangeEvent<string>) => {
              const newValue = event.target.value;
              setAction(newValue);
              setFormState((prev) => ({
                ...prev,
                availability: getAvailabilityPlanned(newValue),
                reason: "",
              }));
            }}
          >
            <MenuItem value={"TURN_ON"}>{t(commons.TURN_ON)}</MenuItem>
            <MenuItem value={"TURN_OFF"}>{t(commons.TURN_OFF)}</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>{t(commons.REASON)}</InputLabel>
          <Select
            label={t(commons.REASON)}
            value={formState.reason}
            endAdornment={handleModalIcon(modal.errors["reason"])}
            disabled={!action}
            onChange={(event: SelectChangeEvent<string>) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                reason: newValue,
              }));
            }}
          >
            {reasons.map((option) => (
              <MenuItem key={option} value={option}>
                {t(`reasonOnOff.${option}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(modal.errors).length,
          }}
        />
      </Grid>
    </Grid>
  );
};
