import { KOSTopbar } from "@foodology-co/alejandria";
import { Box } from "@mui/material";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { toggleMenu } from "app/store/slices/menu";
import appConfig from "config/app";
import React from "react";
import SearchField from "../TopBarSearch/SearchField";

const TopBar = () => {
  const dispatch = useAppDispatch();
  const menuOpen = useAppSelector((state) => state.menu.open);
  const user = useAppSelector((state) => state.session.user.data);
  const title = useAppSelector((state) => state.global.title);
  const canSearchOnTopBar = useAppSelector(
    (state) => state.global.canSearchOnTopBar
  );

  const [flexRightArea, setFlexRightArea] = React.useState(1);

  const toggle = () => {
    dispatch(toggleMenu());
  };

  const isProduction = appConfig.env === "production";

  return (
    <KOSTopbar
      title={title ?? ""}
      flexRightArea={flexRightArea}
      onOpenMenuClick={toggle}
      isDevelopment={!isProduction}
      helpCenterLink="https://foodology.notion.site/Centro-de-ayuda-para-cocinas-f49c4322877e428f907652f9b3d2783c"
      openMenu={menuOpen}
      searchComponent={
        <Box sx={{ display: "flex" }}>
          {canSearchOnTopBar && (
            <SearchField collapsible setFlexRightArea={setFlexRightArea} />
          )}
        </Box>
      }
      username={user?.profile?.name}
      avatarUrl={user?.profileImage}
    />
  );
};

export default TopBar;
