import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { commons } from "app/i18n/types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KitchenSelector from "app/components/Kitchen/Selector";
import CustomSelector from "app/components/common/Selector";
import { Kitchen, Status, TypeOfKitchen } from "core/kitchens/entities/Kitchen";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { compareSearchText } from "utils/general";

interface Props {
  kitchens: Kitchen[];
  setFilteredKitchens: (kitchens: Kitchen[]) => void;
}

const KitchenFilters: FunctionComponent<Props> = (props) => {
  const { kitchens, setFilteredKitchens } = props;

  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedCity, setSelectedCity] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedType, setSelectedType] = useState<string>();
  const [search, setSearch] = useState<string>("");
  const [cleanSearch, setCleanSearch] = useState<number>(0);

  const typeValidator = (kitchen: Kitchen, selected?: string): boolean => {
    if (!selected) return true;
    return kitchen.type === selected;
  };

  const cityValidator = (kitchen: Kitchen, selected?: string): boolean => {
    if (!selected) return true;
    return kitchen.city.code === selected;
  };

  const countryValidator = (kitchen: Kitchen, selected?: string): boolean => {
    if (!selected) return true;
    return kitchen.country === selected;
  };

  const statusValidator = (kitchen: Kitchen, selected?: string): boolean => {
    if (!selected) return true;
    return kitchen.status === selected;
  };

  const searchValidator = (kitchen: Kitchen, toSearch: string): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(kitchen.kitchenId, toSearch) ||
      compareSearchText(kitchen.name, toSearch)
    );
  };

  useEffect(() => {
    setCleanSearch(new Date().getTime());
    setSelectedStatus(undefined);
    setFilteredKitchens(kitchens);
  }, [kitchens]);

  useEffect(() => {
    setFilteredKitchens(
      kitchens.filter((kitchen) => {
        return (
          statusValidator(kitchen, selectedStatus) &&
          countryValidator(kitchen, selectedCountry) &&
          cityValidator(kitchen, selectedCity) &&
          typeValidator(kitchen, selectedType) &&
          searchValidator(kitchen, search)
        );
      })
    );
  }, [selectedStatus, selectedCountry, selectedCity, search, selectedType]);

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item sm={12} xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t(commons.FILTERS)}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <KitchenSelector
                onCountryChange={(value) => setSelectedCountry(value?.code)}
                onCityChange={(value) => setSelectedCity(value?.code)}
                hideLocationSelector
              />
            </Grid>
            <Grid item sm={3}>
              <CustomSelector
                options={Object.keys(TypeOfKitchen)}
                optionPrefix="kitchenManager"
                label={commons.TYPE}
                selected={selectedType}
                setSelected={setSelectedType}
                disabled={!kitchens.length}
              />
            </Grid>
            <Grid item sm={3}>
              <CustomSelector
                options={Object.keys(Status)}
                optionPrefix="common"
                label={commons.STATUS}
                selected={selectedStatus}
                setSelected={setSelectedStatus}
                disabled={!kitchens.length}
              />
            </Grid>
            <SearchOnTopBar clean={cleanSearch} onSearch={setSearch} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default KitchenFilters;
