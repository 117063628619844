import { reasonOnOff } from "app/i18n/types";

export interface Scheduled {
  id?: string;
  storeId: string;
  kitchenId: string;
  country: string;
  brandId: string;
  date: Date;
  action: Action;
  status: Status;
  reason: reasonOnOff;
  timeZone: string;
  platform: string;
}

export interface UpdateStatus {
  id: string;
  status: Status;
}

export enum Status {
  PENDING = "PENDING",
  ARCHIVED = "ARCHIVED",
  DONE = "DONE",
}

export enum Action {
  ON = "ON",
  OFF = "OFF",
}
