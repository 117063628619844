import { Avatar, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { commons } from "app/i18n/types";
import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Actions, BrandModal } from "utils/modal";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Brand } from "core/brand/entities/Brand";
import { KOSBaseTable, KOSBaseTableHeader, KOSRowData } from "@foodology-co/alejandria";

interface Props {
  loading: boolean;
  filteredBrands: Brand[];
  setModal: (modal: BrandModal) => void;
}

const BrandTable: FunctionComponent<Props> = (props) => {
  const { loading, filteredBrands, setModal } = props;

  const { t } = useTranslation();

  const getActions = (row: KOSRowData) => {
    return (
      <>
        <IconButton
          color="warning"
          onClick={() =>
            setModal({
              open: true,
              item: {
                id: row["id"],
              },
              action: Actions.edit,
              onClose: () => {},
            })
          }
        >
          <ModeEditOutlineOutlinedIcon />
        </IconButton>
      </>
    );
  };

  const getImageAndName = (row: KOSRowData) => {
    return (
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar
          alt={row["name"]}
          src={row["logoUrl"]}
          sx={{ width: 30, height: 30 }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          {row["name"]}
        </Typography>
      </Box>
    );
  };

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    return [
      { label: t(commons.ID), field: "brandId" },
      { label: t(commons.NAME), component: (item) => getImageAndName(item) },
      {
        label: t(commons.COUNTRIES),
        text: (item) => item.countries.join(", "),
      },
      {
        label: t(commons.STATION_TYPE),
        align: "center",
        text: (item) => item.stationType.name,
      },
      {
        label: t(commons.ACTION),
        align: "center",
        component: (item) => getActions(item),
      },
    ];
  }, []);

  return (
    <Box mt={2}>
      <KOSBaseTable
        columns={getHeader()}
        pagination={{ enable: true }}
        rows={{
          data: filteredBrands,
          total: filteredBrands.length,
          loading: loading,
        }}
      />
    </Box>
  );
};

export default BrandTable;
