import { Button, Grid } from "@mui/material";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { commons } from "app/i18n/types";
import { useTranslation } from "react-i18next";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import { KOSSelectedResult } from "@foodology-co/alejandria";
import AlarmOnOutlinedIcon from "@mui/icons-material/AlarmOnOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import StoreOnOffDialog from "../OnOff";
import { useState } from "react";
import { SelectedItemAlert } from "../Alerts";
import StoreStatusDialog from "../Status";
import StoreScheduleDialog from "../Schedule";
import { ScheduleType } from "../Schedule/utils";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import PedidosYaDialog from "../PedidosYa";
import OnOffScheduledDialog from "../OnOfScheduled";

interface Props {
  items: KOSSelectedResult[];
  onClose: () => void;
  onSuccess: () => void;
}

const StoreSetUpDialog = (props: Props) => {
  const { items, onClose, onSuccess } = props;
  const itemIds = items.map((el) => el.id) as string[];

  const { t } = useTranslation();
  const [openOnOffDialog, setOpenOnOffDialog] = useState<boolean>(false);
  const [openStatusDialog, setOpenStatusDialog] = useState<boolean>(false);
  const [openScheduleDialog, setOpenScheduleDialog] = useState<boolean>(false);
  const [openScheduledStoreDialog, setOpenScheduledStoreDialog] =
    useState<boolean>(false);
  const [openPedidosYaDialog, setOpenPedidosYaDialog] =
    useState<boolean>(false);
  const [scheduleType, setScheduleType] = useState<ScheduleType>("week");

  const handleSuccess = () => {
    onSuccess();
    onClose();
  };

  const pedidosYa = "PedidosYa";
  const showPedidosYaFunctionality =
    items.length === 1 && items.at(0)?.["platform"] === pedidosYa;

  return (
    <>
      <ManagerDialog
        title={commons.SET_UP}
        onClose={onClose}
        size="xs"
        content={
          <Grid container spacing={2}>
            <SelectedItemAlert items={itemIds} />
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<ToggleOnOutlinedIcon />}
                onClick={() => setOpenStatusDialog(true)}
                fullWidth
              >
                {t(commons.STATUS)} {t(commons.STORE)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<LightbulbOutlinedIcon />}
                onClick={() => setOpenOnOffDialog(true)}
                fullWidth
              >
                {t(commons.TURN_ON)} / {t(commons.TURN_OFF)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<AccessAlarmOutlinedIcon />}
                onClick={() => {
                  setOpenScheduleDialog(true);
                  setScheduleType("week");
                }}
                fullWidth
              >
                {t(commons.UPDATE)} {t(commons.TIMETABLE)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<AlarmOnOutlinedIcon />}
                onClick={() => {
                  setOpenScheduleDialog(true);
                  setScheduleType("theoreticalWeek");
                }}
                fullWidth
              >
                {t(commons.UPDATE)} {t(commons.TIMETABLE)}{" "}
                {t(commons.THEORETICAL)}
              </Button>
            </Grid>
            {showPedidosYaFunctionality && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  startIcon={<DeliveryDiningOutlinedIcon />}
                  fullWidth
                  onClick={() => setOpenPedidosYaDialog(true)}
                >
                  {pedidosYa}
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<TimerOutlinedIcon />}
                fullWidth
                onClick={() => {
                  setOpenScheduledStoreDialog(true);
                }}
              >
                {t(commons.SCHEDULE)} {t(commons.ON)} / {t(commons.OFF)}
              </Button>
            </Grid>
          </Grid>
        }
      />
      {openStatusDialog && (
        <StoreStatusDialog
          title={`${t(commons.STATUS)} ${t(commons.STORE)}`}
          itemIds={itemIds}
          onClose={() => setOpenStatusDialog(false)}
          onSuccess={handleSuccess}
        />
      )}
      {openOnOffDialog && (
        <StoreOnOffDialog
          title={`${t(commons.TURN_ON)} / ${t(commons.TURN_OFF)}`}
          itemIds={itemIds}
          onClose={() => setOpenOnOffDialog(false)}
          onSuccess={handleSuccess}
        />
      )}
      {showPedidosYaFunctionality && openPedidosYaDialog && (
        <PedidosYaDialog
          title="PedidosYa"
          itemIds={itemIds}
          onClose={() => setOpenPedidosYaDialog(false)}
        />
      )}
      {openScheduleDialog && (
        <StoreScheduleDialog
          title={
            scheduleType === "week"
              ? `${t(commons.UPDATE)} ${t(commons.TIMETABLE)}`
              : `${t(commons.UPDATE)} ${t(commons.TIMETABLE)} ${t(
                  commons.THEORETICAL
                )}`
          }
          itemIds={itemIds}
          onClose={() => setOpenScheduleDialog(false)}
          type={scheduleType}
        />
      )}
      {openScheduledStoreDialog && (
        <OnOffScheduledDialog
          title={`${t(commons.STATUS)} ${t(commons.STORE)}`}
          items={items}
          onClose={() => setOpenScheduledStoreDialog(false)}
        />
      )}
    </>
  );
};

export default StoreSetUpDialog;
