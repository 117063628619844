import { commons } from "app/i18n/types";
import { TFunction } from "i18next";
import { CancelOutlined, TaskAlt } from "@mui/icons-material";
import { KOSSelectedResult } from "@foodology-co/alejandria";

export enum Actions {
  add = "ADD",
  edit = "EDIT",
  view = "VIEW",
  config = "CONFIG",
  polygons = "POLYGONS",
}

export interface BaseModal {
  open?: boolean;
  onClose: () => void;
  title?: string;
  action?: Actions;
}

export interface StoreModal extends BaseModal {
  item?: KOSSelectedResult;
}

export interface BrandModal extends BaseModal {
  item?: KOSSelectedResult;
}

export interface KitchenModal extends BaseModal {
  item?: KOSSelectedResult;
}

export const getDialogTitle = (
  t: TFunction,
  title?: string,
  action?: Actions
) => {
  const options = {
    [Actions.add]: t(commons.ADD),
    [Actions.edit]: t(commons.MODIFY),
    [Actions.view]: t(commons.VIEW_DETAIL),
    [Actions.config]: t(commons.SET_UP),
    [Actions.polygons]: t(commons.POLYGONS),
  };

  return title ? t(title) : options[action ?? Actions.add];
};

export const handleModalIcon = (isError: boolean) => {
  if (isError) {
    return <CancelOutlined color="warning" />;
  }
  return <TaskAlt color="success" />;
};
