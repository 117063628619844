import { useAppSelector } from "./useAppSelector";
import { useAppDispatch } from "./useAppDispatch";
import { setBrands } from "app/store/slices/global";
import { useCallback, useState } from "react";
import { Brand, BrandSlice } from "core/brand/entities/Brand";
import { getAllBrands, getBrand } from "core/brand/repository/brandRepo";

export interface UseAllBrands {
  get: () => void;
  findOne: (id: string) => void;
  byBrandId: (brandId: string) => BrandSlice | undefined;
  byCountry: (country: string) => BrandSlice[];
  list: BrandSlice[];
  findOneData: Brand | undefined;
}

export const useAllBrands = (): UseAllBrands => {
  const brands = useAppSelector((state) => state.global.brands);
  const dispatch = useAppDispatch();

  const [findOneItem, setFindOne] = useState<Brand>();

  let findOneLoading = false;

  const findOne = (id: string) => {
    if (findOneLoading) return;
    findOneLoading = true;

    getBrand(id)
      .then((response) => {
        response && setFindOne(response);
      })
      .catch(() => {
        setFindOne(undefined);
      })
      .finally(() => {
        findOneLoading = false;
      });
  };

  let loading = false;
  const getAll = () => {
    if (["loading"].includes(brands.status)) return;
    if (loading) return;
    loading = true;

    dispatch(setBrands({ ...brands, status: "loading" }));

    getAllBrands()
      .then((response) => {
        dispatch(
          setBrands({
            ...brands,
            status: "succeeded",
            data: response.map(
              (brand) =>
                ({
                  id: brand.id ?? "",
                  brandId: brand.brandId,
                  countries: brand.countries,
                  name: brand.name,
                } as BrandSlice)
            ),
          })
        );
      })
      .catch((error) => {
        dispatch(setBrands({ ...brands, status: "failed", error }));
      })
      .finally(() => {
        loading = false;
      });
  };

  const getByBrandId = useCallback(
    (brandId: string) => {
      return brands.data.find((brand) => brand.brandId === brandId);
    },
    [brands]
  );

  const getByCountry = useCallback(
    (country: string) => {
      return brands.data.filter((brand) =>
        country ? brand.countries.includes(country) : true
      );
    },
    [brands]
  );

  return {
    get: getAll,
    byBrandId: getByBrandId,
    byCountry: getByCountry,
    findOne,
    findOneData: findOneItem,
    list: brands.data,
  };
};
