import { useTranslation } from "react-i18next";
import { Actions, getDialogTitle } from "utils/modal";
import { BaseDialogProps } from "./utils";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import HandleForm from "./form";
import { useEffect, useState } from "react";
import { useAllKitchens } from "app/hooks/useAllKitchens";
import HandleFormPolygons from "./polygons";

const KitchenManager: React.FC<BaseDialogProps> = (props) => {
  const { onClose, action, item } = props;
  const { t } = useTranslation();

  const useKitchen = useAllKitchens();
  const dialogTitle = getDialogTitle(t, undefined, action);

  const [itemId, setItemId] = useState<string>();

  useEffect(() => {
    item && setItemId(item["id"]);
  }, [item]);

  useEffect(() => {
    itemId && useKitchen.findOne(itemId);
  }, [itemId]);

  return (
    <ManagerDialog
      title={dialogTitle}
      onClose={onClose}
      size="md"
      content={
        <>
          {action === Actions.polygons && (
            <HandleFormPolygons {...props} kitchen={useKitchen.findOneData} />
          )}
          {action !== Actions.polygons && (
            <HandleForm {...props} kitchen={useKitchen.findOneData} />
          )}
        </>
      }
    />
  );
};

export default KitchenManager;
