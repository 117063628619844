import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDialogTitle } from "utils/modal";
import { BaseDialogProps } from "./utils";
import { HandleForm } from "./form";
import { useAllStore } from "app/hooks/useAllStore";
import { useAllPlatforms } from "app/hooks/useAllPlatforms";

const StoreManagerDialog = (props: BaseDialogProps) => {
  const { onClose, action, item } = props;

  const store = useAllStore();
  const { t } = useTranslation();
  const dialogTitle = getDialogTitle(t, undefined, action);
  const platforms = useAllPlatforms().list;

  const [itemId, setItemId] = useState<string>();

  useEffect(() => {
    item && setItemId(item["id"]);
  }, [item]);

  useEffect(() => {
    itemId &&
      store.findOne(
        itemId,
        "data=warehouse_id&data=legacy_config&data=isPriority"
      );
  }, [itemId]);

  return (
    <ManagerDialog
      title={dialogTitle}
      onClose={onClose}
      size="md"
      content={
        <HandleForm {...props} item={store.findOneData} platforms={platforms} />
      }
    />
  );
};

export default StoreManagerDialog;
