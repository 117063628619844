import { FormControl, Grid, LinearProgress, TextField } from "@mui/material";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { commons } from "app/i18n/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleModalIcon } from "utils/modal";
import {
  BaseDialogProps,
  defaultPedidosYaConfig,
  FormState,
  handleValidations,
} from "./utils";
import { Store, UpdatePedidosYaPayload } from "core/stores/entities/Store";
import { useModal } from "app/hooks/useModal";
import { storePedidosYaUpdate } from "core/stores/repository/storeRepo";
import { SelectedItemAlert } from "../Alerts";

interface Props extends BaseDialogProps {
  item?: Store;
}

export const HandleForm = (props: Props) => {
  const { item: initialValues, itemIds, onClose } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const modal = useModal();

  const toFormState = (): FormState => ({
    ids: itemIds,
    config: initialValues?.data?.["pedidosya_config"] ?? defaultPedidosYaConfig,
  });

  const [formState, setFormState] = useState<FormState>(() => toFormState());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setFormState(() => toFormState());
  }, [initialValues]);

  useEffect(() => {
    modal.validator(handleValidations, { ...formState.config });
  }, [formState]);

  const getResponse = (data: FormState) => {
    return storePedidosYaUpdate(data as UpdatePedidosYaPayload);
  };

  const handleSubmit = useCallback(() => {
    if (!handleValidations.isValidSync({ ...formState.config })) {
      return;
    }
    setIsLoading(true);
    getResponse(formState)
      .then(() => {
        alert.success();
        onClose();
      })
      .catch(() => {
        alert.error();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formState, props.action]);

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <SelectedItemAlert items={itemIds} />

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            type="text"
            variant="outlined"
            label={"Chain ID"}
            value={formState.config.chainId}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["chainId"]),
            }}
            onChange={(event) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                config: {
                  ...prev.config,
                  chainId: newValue,
                },
              }));
            }}
            focused={!!formState.config.chainId}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            type="text"
            variant="outlined"
            label={"Remote ID"}
            value={formState.config.remoteId}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["remoteId"]),
            }}
            onChange={(event) => {
              const newValue = event.target.value;
              setFormState((prev) => ({
                ...prev,
                config: {
                  ...prev.config,
                  remoteId: newValue,
                },
              }));
            }}
            focused={!!formState.config.remoteId}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(modal.errors).length,
          }}
        />
      </Grid>
    </Grid>
  );
};
