import { useState } from "react";
import { storeFindOne } from "core/stores/repository/storeRepo";
import { Store } from "core/stores/entities/Store";

export interface UseStore {
  findOne: (id: string, params?: string) => void;
  findOneData: Store | undefined;
}

export const useAllStore = (): UseStore => {
  const [findOneItem, setFindOne] = useState<Store>();

  let findOneLoading = false;

  const findOne = (id: string, params?: string) => {
    if (findOneLoading) return;
    findOneLoading = true;

    storeFindOne(id, params)
      .then((response) => {
        response && setFindOne(response);
      })
      .catch(() => {
        setFindOne(undefined);
      }).finally(() => {
        findOneLoading = false;
      });
  };

  return {
    findOne,
    findOneData: findOneItem,
  };
};
