import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { commons } from "app/i18n/types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KitchenSelector from "app/components/Kitchen/Selector";
import { Store, StoreStatus } from "core/stores/entities/Store";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { compareSearchText, SelectedString } from "utils/general";
import CustomSelector from "app/components/common/Selector";

interface Props {
  stores: Store[];
  country: SelectedString;
  city: SelectedString;
  kitchen: SelectedString;
  status: SelectedString;
  setFilteredStores: (stores: Store[]) => void;
}

const StoreFilters: FunctionComponent<Props> = (props) => {
  const { country, city, kitchen, stores, setFilteredStores, status } = props;

  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [selectedPlatform, setSelectedPlatform] = useState<string>();
  const [selectedBrand, setSelectedBrand] = useState<string>();
  const [cleanSearch, setCleanSearch] = useState<number>(0);

  const searchValidator = (store: Store, toSearch: string): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(store.externalId, toSearch) ||
      compareSearchText(store.brandId, toSearch)
    );
  };

  const platformValidator = (store: Store, platform?: string): boolean => {
    if (!platform) return true;
    return store.platform === platform;
  };

  const brandValidator = (store: Store, brand?: string): boolean => {
    if (!brand) return true;
    return store.brandId === brand;
  };

  useEffect(() => {
    setCleanSearch(new Date().getTime());
    setSelectedPlatform(undefined);
    setFilteredStores(stores);
  }, [stores]);

  useEffect(() => {
    setFilteredStores(
      stores.filter((store) => {
        return (
          searchValidator(store, search) &&
          platformValidator(store, selectedPlatform) &&
          brandValidator(store, selectedBrand)
        );
      })
    );
  }, [search, selectedPlatform, selectedBrand]);

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item sm={12} xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t(commons.FILTERS)}
          </Typography>
          <SearchOnTopBar onSearch={setSearch} clean={cleanSearch} />
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <KitchenSelector
                onCountryChange={(value) => country.onChange(value?.code)}
                onCityChange={(value) => city.onChange(value?.code)}
                onKitchenChange={(value) => kitchen.onChange(value?.kitchenId)}
              />
            </Grid>
            <Grid item sm={2}>
              <CustomSelector
                options={Object.keys(StoreStatus)}
                optionPrefix="storeStatus"
                label={commons.STATUS}
                selected={status.value}
                setSelected={status.onChange}
                disabled={!stores.length}
              />
            </Grid>
            <Grid item sm={2}>
              <CustomSelector
                options={Array.from(new Set(stores.map((el) => el.platform)))}
                label={commons.PLATFORM}
                selected={selectedPlatform}
                setSelected={setSelectedPlatform}
                disabled={!stores.length}
              />
            </Grid>
            <Grid item sm={2}>
              <CustomSelector
                options={Array.from(new Set(stores.map((el) => el.brandId)))}
                label={commons.BRAND}
                selected={selectedBrand}
                setSelected={setSelectedBrand}
                disabled={!stores.length}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StoreFilters;
