import CloseIcon from "@mui/icons-material/Close";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import SendIcon from "@mui/icons-material/Send";
import {
  AppBar,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { commons, kitchenManager } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { TransitionDialogUp } from "utils/generalUI";
import { StationConfigSelector } from "./Selector";
interface Props {
  onClose: () => void;
  kitchens: string[];
}

export const StationConfigModal: FunctionComponent<Props> = (props) => {
  const { onClose, kitchens } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen
      open
      onClose={onClose}
      TransitionComponent={TransitionDialogUp}
    >
      <AppBar sx={{ background: "white" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="primary"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            color="primary"
          >
            {t(kitchenManager.SETUP_TITLE)}
          </Typography>
          <Button
            onClick={onClose}
            variant="outlined"
            color="primary"
            startIcon={<CloseOutlinedIcon />}
            sx={{ py: 1, px: 2, fontWeight: "600" }}
          >
            {t(commons.RETURN)}
          </Button>
        </Toolbar>
      </AppBar>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2,
          mt: 8,
          mx: 2,
        }}
      >
        <Grid container spacing={2}>
          {kitchens.map((kitchen) => (
            <Grid item xs={4} sm={3} md={1.5}>
              <Card sx={{ p: 1, textAlign: "center", fontWeight: "600" }}>
                {kitchen}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={3}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="actions-list"
            >
              <ListItemButton>
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary={t(kitchenManager.LINK_STATION)} />
              </ListItemButton>
            </List>
          </Grid>
          <Grid item xs={9}>
            <StationConfigSelector kitchens={kitchens} />
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};
