import React, { useState, useCallback } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import es from "date-fns/locale/es";
import { BaseDialogProps } from "./utils";
import { commons, reasonOnOff, alertMessage } from "app/i18n/types";
import { useTranslation } from "react-i18next";
import { Action, Scheduled, Status } from "core/stores/entities/Scheduled";
import { createScheduled } from "core/stores/repository/scheduled";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAllCountries } from "app/hooks/useAllCountries";

interface Props extends BaseDialogProps {}

export const HandleForm = (props: Props) => {
  const { t } = useTranslation();
  const { items, onClose } = props;

  const useCountries = useAllCountries();

  const [selectedAction, setSelectedAction] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<
    keyof typeof reasonOnOff
  >("OPERATING_CORRECTLY");
  const [selectedDateTime, setSelectedDateTime] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleActionChange = (event: SelectChangeEvent<string>) => {
    setSelectedAction(event.target.value);
  };

  const handleReasonChange = (
    event: SelectChangeEvent<keyof typeof reasonOnOff>
  ) => {
    const newReason = event.target.value as keyof typeof reasonOnOff;
    setSelectedReason(newReason);
  };

  const handleDateTimeChange = (date: Date | null) => {
    setSelectedDateTime(date || new Date());
  };

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const scheduledList: Scheduled[] = items.map((store) => {
      const timeZone = useCountries.byCode(store["country"])?.zone;

      return {
        storeId: store["id"],
        kitchenId: store["kitchenId"],
        country: store["country"],
        brandId: store["brandId"],
        date: selectedDateTime,
        action: selectedAction as Action,
        status: Status.PENDING,
        reason: selectedReason as reasonOnOff,
        timeZone: timeZone ?? "",
        platform: store["platform"],
      };
    });

    try {
      await Promise.all(
        scheduledList.map((scheduled) => createScheduled(scheduled))
      );
    } catch (error) {
      console.error("Error creating scheduled:", error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  }, [selectedAction, selectedReason, selectedDateTime, onClose]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6" component="div">
              {t(commons.SCHEDULE)} {t(commons.ON)} / {t(commons.OFF)}
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ position: "relative", padding: 2 }}>
            <Grid container spacing={3}>
              {isLoading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: "#e3f2fd",
                    p: 1,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InfoOutlinedIcon color="info" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    {t(alertMessage.ROWS_SELECTED, { rows: items.length })}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <DateTimePicker
                  label={t(commons.DATE)}
                  value={selectedDateTime}
                  onChange={handleDateTimeChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t(commons.ACTION)}</InputLabel>
                  <Select
                    value={selectedAction}
                    onChange={handleActionChange}
                    label={t(commons.ACTION)}
                  >
                    <MenuItem value="ON">{t(commons.TURN_ON)}</MenuItem>
                    <MenuItem value="OFF">{t(commons.TURN_OFF)}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t(commons.REASON)}</InputLabel>
                  <Select
                    value={selectedReason}
                    onChange={handleReasonChange}
                    label={t(commons.REASON)}
                    disabled={!selectedAction}
                  >
                    {Object.entries(reasonOnOff).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {t(value)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Grid item xs={12} sx={{ padding: 2 }}>
          <ManagerDialogFooter
            onCancel={props.onClose}
            mainButton={{
              children: t(commons.SAVE),
              onClick: handleSubmit,
              disabled: isLoading,
            }}
          />
        </Grid>
      </Dialog>
    </LocalizationProvider>
  );
};
