export enum FoodologyCountry {
  COL = "COL",
  MEX = "MEX",
  PER = "PER",
  BRA = "BRA",
  ECU = "ECU",
}

export interface Country {
  code: string;
  paymentCurrency: string;
  zone: string;
  domain: string;
  cookingTime: string;
}

export interface CountryResponse {
  data: Country[];
}
