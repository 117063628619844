import ModuleKitchens from "app/pages/Kitchens";
import ModuleStores from "app/pages/Stores";
import ModuleBrands from "app/pages/Brands";
import { TypeOfRole as Role, TypeOfRole } from "utils/role";

export interface CustomRoute {
  path: string;
  element?: JSX.Element;
  childrens?: CustomRoute[];
  roles?: TypeOfRole[];
}

export const rolesWithAllAccess = [
  Role.TECH,
  Role.PRODUCTO,
  Role.TECH_OPS,
  Role.ADMIN,
  Role.INVENTORY_ANALYST,
  Role.CITY_MANAGER,
  Role.COUNTRY_MANAGER,
];

export const routeConfig: CustomRoute[] = [
  {
    path: "stores",
    element: <ModuleStores />,
  },
  {
    path: "kitchens",
    element: <ModuleKitchens />,
  },
  {
    path: "brands",
    element: <ModuleBrands />,
  },
];
