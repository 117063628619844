import { StoreModal } from "utils/modal";
import { commons } from "app/i18n/types";
import { object, string } from "yup";
import { BrandSeed, EnablePlatform } from "core/brand/entities/Brand";

export interface BaseDialogProps extends StoreModal {
  onSuccess: () => void;
}

export const defaultEnablePlatform: EnablePlatform = {
  active: false,
};

export interface FormState extends Partial<BrandSeed> {}

export const handleValidations = object({
  brandId: string().required(commons.REQUIRED_FIELD),
  stationTypeId: string().required(commons.REQUIRED_FIELD),
  name: string().required(commons.REQUIRED_FIELD),
});

export const defaultData = {
  countries: [],
  platforms: {},
};
