import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { useTranslation } from "react-i18next";
import { getDialogTitle } from "utils/modal";
import { BaseDialogProps } from "./utils";
import { HandleForm } from "./form";

const OnOffScheduledDialog = (props: BaseDialogProps) => {
  const { onClose, action, title, open } = props;

  const { t } = useTranslation();
  const dialogTitle = getDialogTitle(t, title, action);

  return (
    <ManagerDialog
      open={open}
      title={dialogTitle}
      onClose={onClose}
      size="xs"
      content={<HandleForm {...props} />}
    />
  );
};

export default OnOffScheduledDialog;
