import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Alert,
  BrandsState,
  CitiesState,
  CountriesState,
  defaultData,
  initialState,
  KitchensState,
  PlatformsState,
  StationTypesState,
} from "./state";

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    showAlert: (state, alert: PayloadAction<Alert>) => {
      state.alert = { ...alert.payload, show: true };
    },
    showSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
      state.canSearchOnTopBar = true;
    },
    hideSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
      state.canSearchOnTopBar = false;
    },
    setSearchOnTopBar: (state, action: PayloadAction<string>) => {
      state.textSearchOnTopBar = action.payload;
    },
    clearSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
    },
    hideAlert: (state) => {
      state.alert = { show: false };
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    clearTitle: (state) => {
      state.title = null;
    },
    setKitchens: (state, action: PayloadAction<KitchensState>) => {
      state.kitchens = action.payload;
    },
    clearKitchens: (state) => {
      state.kitchens = defaultData as KitchensState;
    },
    setPlatforms: (state, action: PayloadAction<PlatformsState>) => {
      state.platforms = action.payload;
    },
    clearPlatforms: (state) => {
      state.platforms = defaultData as PlatformsState;
    },
    setCities: (state, action: PayloadAction<CitiesState>) => {
      state.cities = action.payload;
    },
    clearCities: (state) => {
      state.cities = defaultData as CitiesState;
    },
    setCountries: (state, action: PayloadAction<CountriesState>) => {
      state.countries = action.payload;
    },
    clearCountries: (state) => {
      state.countries = defaultData as CountriesState;
    },
    setBrands: (state, action: PayloadAction<BrandsState>) => {
      state.brands = action.payload;
    },
    clearBrands: (state) => {
      state.brands = defaultData as BrandsState;
    },
    setStationTypes: (state, action: PayloadAction<StationTypesState>) => {
      state.stationTypes = action.payload;
    },
    clearStationTypes: (state) => {
      state.stationTypes = defaultData as StationTypesState;
    },
  },
});

export const {
  showAlert,
  hideAlert,
  setTitle,
  clearTitle,
  showSearchOnTopBar,
  hideSearchOnTopBar,
  setSearchOnTopBar,
  clearSearchOnTopBar,
  setKitchens,
  clearKitchens,
  setPlatforms,
  clearPlatforms,
  setCities,
  clearCities,
  setCountries,
  clearCountries,
  setBrands,
  clearBrands,
  setStationTypes,
  clearStationTypes
} = globalSlice.actions;
export const { reducer } = globalSlice;

export default globalSlice;
