import { StoreModal } from "utils/modal";
import { commons } from "app/i18n/types";
import { object, string } from "yup";
import { Store, StoreSchedule } from "core/stores/entities/Store";

export interface BaseDialogProps extends StoreModal {
  country?: string;
  city?: string;
  kitchenId?: string;
  onSuccess: () => void;
}

export const defaultDataSchedule: StoreSchedule = {
  monday: ["00:00:00-00:00:00"],
  tuesday: ["00:00:00-00:00:00"],
  wednesday: ["00:00:00-00:00:00"],
  thursday: ["00:00:00-00:00:00"],
  friday: ["00:00:00-00:00:00"],
  saturday: ["00:00:00-00:00:00"],
  sunday: ["00:00:00-00:00:00"],
};

export interface FormState extends Partial<Store> {}

export const handleValidations = object({
  warehouseId: string().required(commons.REQUIRED_FIELD),
  externalId: string().required(commons.REQUIRED_FIELD),
  platform: string().required(commons.REQUIRED_FIELD),
  brandId: string().required(commons.REQUIRED_FIELD),
  country: string().required(commons.REQUIRED_FIELD),
  city: string().required(commons.REQUIRED_FIELD),
  kitchenId: string().required(commons.REQUIRED_FIELD),
});

export const defaultData = {
  legacy_schedule: {
    week: defaultDataSchedule,
    theoreticalWeek: defaultDataSchedule,
    timeZone: "",
  },
  legacy_config: {
    isParentStore: false,
    isMarketPlace: false,
  },
};
