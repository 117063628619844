import { KOSSelectedResult } from "@foodology-co/alejandria";
import PageHeader from "app/components/PageHeader";
import { commons, storeManager } from "app/i18n/types";
import {
  Store,
  StoreStatus,
  StoreStatusType,
} from "core/stores/entities/Store";
import { storesByQuery } from "core/stores/repository/storeRepo";
import { getAllScheduled } from "core/stores/repository/scheduled";
import { FunctionComponent, useEffect, useState } from "react";
import { PageBox } from "utils/module";
import StoreFilters from "./Filters";
import { useTranslation } from "react-i18next";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import StoreSetUpDialog from "app/components/Store/SetUp/Dialog";
import StoreTable from "app/components/Store/Table";
import { Actions, StoreModal } from "utils/modal";
import StoreManagerDialog from "app/components/Store/Maganer";
import { Scheduled } from "core/stores/entities/Scheduled";
import CustomTabs from "app/components/common/Tabs";
import OnOffScheduledTable from "app/components/Store/SetUp/OnOfScheduled/Table";

interface Props {}

const StorePage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [stores, setStores] = useState<Store[]>([]);
  const [filteredStores, setFilteredStores] = useState<Store[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedCity, setSelectedCity] = useState<string>();
  const [selectedKitchen, setSelectedKitchen] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [openSetUpDialog, setOpenSetUpDialog] = useState<boolean>(false);
  const [modal, setModal] = useState<StoreModal>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [scheduledList, setScheduledList] = useState<Scheduled[]>([]);
  const [filteredScheduled, setFilteredScheduled] = useState<Scheduled[]>([]);

  const getData = () => {
    setOpenSetUpDialog(false);
    setSelectedItems([]);
    setStores([]);

    setLoading(true);
    storesByQuery({
      cursor: { limit: 100000 },
      params: {
        include: (selectedStatus
          ? [selectedStatus]
          : Object.keys(StoreStatus)) as StoreStatusType[],
        location: {
          country: selectedCountry,
          city: selectedCity,
          kitchen: selectedKitchen,
        },
      },
      select: ["legacy_config", "legacy_schedule", "statusReason"],
    })
      .then((response) => {
        setStores(response.list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getScheduledData = () => {
    setLoading(true);
    getAllScheduled()
      .then((response) => {
        setScheduledList(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCountry) {
      const filtered = scheduledList.filter(
        (scheduled) => scheduled.country === selectedCountry
      );
      setFilteredScheduled(filtered);
    } else {
      setFilteredScheduled(scheduledList);
    }
  }, [selectedCountry, scheduledList]);

  useEffect(() => {
    if (tabIndex === 0) getData();
    setFilteredScheduled(
      scheduledList.filter((el) => {
        return !selectedCountry || el.country === selectedCountry;
      })
    );
  }, [selectedCountry, selectedCity, selectedKitchen, selectedStatus]);

  useEffect(() => {
    if (modal?.action === Actions.config) {
      setOpenSetUpDialog(true);
    }
  }, [modal]);

  const onCloseModal = () => setModal(undefined);

  const onRefresh = () => {
    if (tabIndex === 0) getData();
    if (tabIndex === 1) getScheduledData();
  };

  useEffect(() => {
    onRefresh();
  }, [tabIndex]);

  return (
    <PageBox>
      <PageHeader
        title={storeManager.TITLE}
        rightArea={[
          {
            children: t(commons.CREATE),
            variant: "contained",
            startIcon: <AddOutlinedIcon />,
            show: tabIndex === 0,
            onClick: () =>
              setModal({
                open: true,
                action: Actions.add,
                onClose: onCloseModal,
              }),
          },
          {
            children: t(commons.SET_UP),
            variant: "outlined",
            color: "warning",
            startIcon: <SettingsOutlinedIcon />,
            onClick: () => setOpenSetUpDialog(true),
            show: selectedItems.length > 1,
          },
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: onRefresh,
          },
        ]}
      />
      <StoreFilters
        stores={stores}
        country={{ onChange: setSelectedCountry }}
        city={{ onChange: setSelectedCity }}
        kitchen={{ onChange: setSelectedKitchen }}
        status={{ value: selectedStatus, onChange: setSelectedStatus }}
        setFilteredStores={setFilteredStores}
      />

      <CustomTabs
        tabs={[
          {
            children: t(storeManager.STORES),
          },
          {
            children: t(storeManager.SCHEDULED),
          },
        ]}
        onChange={setTabIndex}
      />

      {tabIndex === 0 && (
        <StoreTable
          filteredStores={filteredStores}
          loading={loading}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setModal={setModal}
        />
      )}

      {tabIndex === 1 && (
        <OnOffScheduledTable
          filteredScheduled={filteredScheduled}
          loading={loading}
          onArchiveSuccess={getScheduledData}
        />
      )}

      {modal?.open && (
        <StoreManagerDialog
          {...modal}
          country={selectedCountry}
          city={selectedCity}
          kitchenId={selectedKitchen}
          onClose={onCloseModal}
          onSuccess={getData}
        />
      )}

      {openSetUpDialog && (
        <StoreSetUpDialog
          items={modal?.item ? [modal.item] : selectedItems}
          onClose={() => {
            setOpenSetUpDialog(false);
            setModal(undefined);
          }}
          onSuccess={getData}
        />
      )}
    </PageBox>
  );
};

export default StorePage;
