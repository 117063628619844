import { useAppSelector } from "./useAppSelector";
import { useAppDispatch } from "./useAppDispatch";
import { setStationTypes } from "app/store/slices/global";
import { StationResponse, StationType } from "core/station/entities/Station";
import {
  allStationType,
  stationsByKitchen,
} from "core/station/repository/StationRepo";
import { useState } from "react";

export interface UseAllStationTypes {
  get: () => void;
  list: StationType[];
  getStationsByKitchen: (kitchenId: string) => void;
  stationsByKichen: StationResponse[];
}

export const useAllStationTypes = (): UseAllStationTypes => {
  const stationTypes = useAppSelector((state) => state.global.stationTypes);
  const dispatch = useAppDispatch();
  const [stationsByKichen, setStationsByKitchen] = useState<StationResponse[]>(
    []
  );
  let loading = false;

  const getAll = () => {
    if (["loading"].includes(stationTypes.status)) return;
    if (loading) return;
    loading = true;

    dispatch(setStationTypes({ ...stationTypes, status: "loading" }));

    allStationType()
      .then((response) => {
        dispatch(
          setStationTypes({
            ...stationTypes,
            status: "succeeded",
            data: response,
          })
        );
      })
      .catch((error) => {
        dispatch(setStationTypes({ ...stationTypes, status: "failed", error }));
      })
      .finally(() => {
        loading = false;
      });
  };

  const getStationsByKitchen = (kitchenId: string) => {
    if (!kitchenId) setStationsByKitchen([]);
    if (loading) return;
    loading = true;
    stationsByKitchen(kitchenId)
      .then((response) => {
        setStationsByKitchen(response);
      })
      .catch(() => {
        setStationsByKitchen([]);
      })
      .finally(() => {
        loading = false;
      });
  };

  return {
    get: getAll,
    list: stationTypes.data,
    getStationsByKitchen,
    stationsByKichen,
  };
};
