import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { commons, scheduled } from "app/i18n/types";
import { updateStatus } from "core/stores/repository/scheduled";
import { Status, UpdateStatus } from "core/stores/entities/Scheduled";

interface ArchiveDialogProps {
  itemId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const OnOffArchiveDialog: React.FunctionComponent<ArchiveDialogProps> = (
  props
) => {
  const { itemId, onClose, onSuccess } = props;
  const { t } = useTranslation();

  const handleDelete = () => {
    if (!itemId) return;
    const updateData: UpdateStatus = {
      id: itemId,
      status: Status.ARCHIVED,
    };
    try {
      updateStatus(updateData).then(() => {
        onSuccess();
        onClose();
      });
    } catch (error) {
      console.error("Error deleting scheduled item:", error);
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{t(scheduled.ARCHIVE)}</DialogTitle>
      <DialogContent>{t(scheduled.CHAGE_STATUS_MESSAGE)}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t(commons.CANCEL)}
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          {t(commons.ACCEPT)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnOffArchiveDialog;
