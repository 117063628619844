import appConfig from "config/app";
import { ApiVersion, getHttp, postHttp } from "utils/http";
import { Brand, BrandSeed } from "../entities/Brand";

export const getAllBrands = async (): Promise<Brand[]> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `brand/all`
    );
    const data = await response.json();
    return data.data as Brand[];
  } catch (ex: any) {
    console.error(`Error getting all brands | ${ex.message}`);
    return [] as Brand[];
  }
};

export const upsertBrand = async (seed: BrandSeed): Promise<Brand[]> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `brand/upsert`,
      seed
    );
    const data = await response.json();
    return data.data as Brand[];
  } catch (ex: any) {
    console.error(`Error in upsertBrand | ${ex.message}`);
    return [] as Brand[];
  }
};

export const getBrand = async (brandId: string): Promise<Brand | undefined> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `brand/${brandId}`
    );
    const data = await response.json();
    return data.data as Brand;
  } catch (ex: any) {
    console.error(`Error in getBrand | ${ex.message}`);
    return undefined;
  }
};
