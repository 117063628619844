import appConfig from "config/app";
import { Session } from "core/account/entities/Session";
import { SessionNotFoundError } from "core/account/exceptions/session";
import { CookieItem, getCookie, removeCookie } from "./cookie";

export const sessionValidator = (session: Session) => {
  const {
    kitchenDisplay: { url: kds },
    bistroKeeper: { url },
  } = appConfig;
  const redirect = `${kds}/login?redirect=${url}${window.location.pathname}`;
  if (!session) {
    window.location.href = redirect;
    throw new SessionNotFoundError("Cookie session not found");
  }
  const { userId, token } = session;
  if (!userId || !token) {
    window.location.href = redirect;
    throw new SessionNotFoundError("Cookie session not found");
  }
};

export const getSession = (): Session => {
  const cookieUserId = getCookie(CookieItem.userId);
  const cookieLoginToken = getCookie(CookieItem.loginToken);
  const cookieCompanyId = getCookie(CookieItem.companyId);

  const session: Session = {
    userId: cookieUserId ?? "",
    token: cookieLoginToken ?? "",
    companyId: cookieCompanyId ?? "fdgy",
  };

  sessionValidator(session);

  return session;
};

export const removeSession = (): void => {
  const cookieUserId = getCookie(CookieItem.userId);
  if (cookieUserId) {
    removeCookie(CookieItem.userId);
  }
  const cookieLoginToken = getCookie(CookieItem.loginToken);
  if (cookieLoginToken) {
    removeCookie(CookieItem.loginToken);
  }
};
