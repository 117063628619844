import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { commons } from "app/i18n/types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KitchenSelector from "app/components/Kitchen/Selector";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { compareSearchText } from "utils/general";
import { Brand } from "core/brand/entities/Brand";
import { useAllStationTypes } from "app/hooks/useAllStationTypes";
import CustomSelector from "app/components/common/Selector";

interface Props {
  brands: Brand[];
  setFilteredBrands: (brands: Brand[]) => void;
}

const BrandFilters: FunctionComponent<Props> = (props) => {
  const { brands, setFilteredBrands } = props;

  const { t } = useTranslation();
  const stationTypes = useAllStationTypes().list;
  const brandIds = [...new Set(brands.map((el) => el.brandId))];

  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedStationType, setSelectedStationType] = useState<string>();
  const [selectedBrandId, setSelectedBrandId] = useState<string>();
  const [toSearch, setToSearch] = useState<string>("");
  const [cleanSearch, setCleanSearch] = useState<number>(0);

  const countryValidator = (brand: Brand, selected?: string): boolean => {
    if (!selected) return true;
    return brand.countries.includes(selected ?? "");
  };

  const stationTypeValidator = (brand: Brand, selected?: string): boolean => {
    if (!selected) return true;
    return brand.stationType.name === selected;
  };

  const brandIdValidator = (brand: Brand, selected?: string): boolean => {
    if (!selected) return true;
    return brand.brandId === selected;
  };

  const toSearchValidator = (brand: Brand, text?: string): boolean => {
    if (!text) return true;
    return (
      compareSearchText(brand.brandId, text) ||
      compareSearchText(brand.name, text)
    );
  };

  useEffect(() => {
    setCleanSearch(new Date().getTime());
    setSelectedStationType(undefined);
    setSelectedBrandId(undefined);
    setFilteredBrands(brands);
  }, [brands]);

  useEffect(() => {
    setFilteredBrands(
      brands.filter((brand) => {
        return (
          toSearchValidator(brand, toSearch) &&
          countryValidator(brand, selectedCountry) &&
          brandIdValidator(brand, selectedBrandId) &&
          stationTypeValidator(brand, selectedStationType)
        );
      })
    );
  }, [toSearch, selectedCountry, selectedStationType, selectedBrandId]);

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item sm={12} xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t(commons.FILTERS)}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <KitchenSelector
                onCountryChange={(value) => setSelectedCountry(value?.code)}
                hideCitySelector
                hideLocationSelector
              />
            </Grid>
            <Grid item sm={4}>
              <CustomSelector
                options={stationTypes.map((el) => el.name)}
                label={commons.STATION_TYPE}
                selected={selectedStationType}
                setSelected={setSelectedStationType}
                disabled={!brands.length}
              />
            </Grid>
            <Grid item sm={4}>
              <CustomSelector
                options={brandIds}
                label={commons.ID}
                selected={selectedBrandId}
                setSelected={setSelectedBrandId}
                disabled={!brands.length}
              />
            </Grid>
            <SearchOnTopBar clean={cleanSearch} onSearch={setToSearch} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BrandFilters;
