/* eslint-disable no-useless-computed-key */
import { Grid, LinearProgress } from "@mui/material";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { commons } from "app/i18n/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseDialogProps,
  defaultDataSchedule,
  FormState,
  handleValidations,
  scheduleRefined,
} from "./utils";
import { SetLegacySchedulePayload, Store, StoreSchedule } from "core/stores/entities/Store";
import { useModal } from "app/hooks/useModal";
import { SelectedItemAlert } from "../Alerts";
import { dayOfTheWeek } from "utils/general";
import { HandleFormRow } from "./row";
import { storeSetLegacySchedule } from "core/stores/repository/storeRepo";

interface Props extends BaseDialogProps {
  item?: Store;
}

export const HandleForm = (props: Props) => {
  const { item: initialValues, itemIds, onClose, type } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const modal = useModal();

  const toFormState = (): FormState => ({
    ids: itemIds,
    type,
    data: initialValues?.data?.["legacy_schedule"]?.[type] ?? defaultDataSchedule,
  });

  const [formState, setFormState] = useState<FormState>(() => toFormState());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [legacySchedule, setLegacySchedule] = useState<StoreSchedule>(defaultDataSchedule);

  useEffect(() => {
    setFormState(() => toFormState());
    setLegacySchedule(initialValues?.data?.["legacy_schedule"]?.[type] ?? defaultDataSchedule);
  }, [initialValues]);

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      data: legacySchedule,
    }));
  }, [legacySchedule]);

  useEffect(() => {
    modal.validator(handleValidations, scheduleRefined(legacySchedule));
  }, [formState]);

  const getResponse = (data: FormState) => {
    return storeSetLegacySchedule(data as SetLegacySchedulePayload);
  };

  const handleSubmit = useCallback(() => {
    if (!handleValidations.isValidSync(scheduleRefined(legacySchedule))) {
      return;
    }
    setIsLoading(true);
    getResponse(formState)
      .then(() => {
        alert.success();
        onClose();
      })
      .catch(() => {
        alert.error();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formState, props.action]);

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <SelectedItemAlert items={itemIds} />

      {dayOfTheWeek.map((day) => (
        <HandleFormRow
          day={day}
          modal={modal}
          schedule={legacySchedule}
          setSchedule={setLegacySchedule}
        />
      ))}

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(modal.errors).length,
          }}
        />
      </Grid>
    </Grid>
  );
};
