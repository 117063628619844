import { StoreAvailability, StoreAvailabilityOperation } from "core/stores/entities/Store";
import { BaseModal } from "utils/modal";
import { commons } from "app/i18n/types";
import { object, string } from "yup";

export interface BaseDialogProps extends BaseModal {
  onSuccess: () => void;
  itemIds: string[];
}

export interface FormState {
  ids: string[];
  availability?: StoreAvailability;
  reason: string;
}

export const handleValidations = object({
  action: string().required(commons.REQUIRED_FIELD),
  reason: string().required(commons.REQUIRED_FIELD),
});

export const getAvailabilityPlanned = (newValue: string): StoreAvailability => {
  const operation = StoreAvailabilityOperation.Launched;
  const paused = newValue === "TURN_ON" ? false : true;
  return { operation, paused } as StoreAvailability;
};
