import {
  StoreAvailability,
  StoreAvailabilityOperation,
  StoreStatus2,
} from "core/stores/entities/Store";
import { BaseModal } from "utils/modal";
import { commons } from "app/i18n/types";
import { object, string } from "yup";

export interface BaseDialogProps extends BaseModal {
  onSuccess: () => void;
  itemIds: string[];
}

export interface FormState {
  ids: string[];
  availability?: StoreAvailability;
}

export const handleValidations = object({
  action: string().required(commons.REQUIRED_FIELD),
});

export const getAvailabilityRemote = (newValue: string): StoreAvailability => {
  let result = {};
  switch (newValue) {
    case StoreStatus2.OPEN:
      result = {
        operation: StoreAvailabilityOperation.Launched,
        paused: false,
      };
      break;
    case StoreStatus2.TEMP_CLOSED:
      result = {
        operation: StoreAvailabilityOperation.Launched,
        paused: true,
      };
      break;
    case StoreStatus2.NOT_LAUNCHED:
      result = {
        operation: StoreAvailabilityOperation.Upcoming,
      };
      break;
    case StoreStatus2.PERM_CLOSED:
      result = {
        operation: StoreAvailabilityOperation.Terminated,
      };
      break;
  }
  return result as StoreAvailability;
};
