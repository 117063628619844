import { commons } from "app/i18n/types";
import { City } from "core/city/entities/City";
import { FoodologyCountry } from "core/country/entities/Country";

const allLabel = "ALL";

export const allCity: City = {
  id: allLabel,
  name: commons.ALL_CITIES,
  code: allLabel,
  country: FoodologyCountry.COL,
  slug: allLabel,
  logoUrl: "",
  timeZone: "",
};
