import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDialogTitle } from "utils/modal";
import { BaseDialogProps } from "./utils";
import { HandleForm } from "./form";
import { useAllStationTypes } from "app/hooks/useAllStationTypes";
import { useAllBrands } from "app/hooks/useAllBrands";

const BrandManagerDialog = (props: BaseDialogProps) => {
  const { onClose, action, item } = props;

  const brand = useAllBrands();
  const { t } = useTranslation();
  const dialogTitle = getDialogTitle(t, undefined, action);
  const stationTypes = useAllStationTypes().list;

  const [itemId, setItemId] = useState<string>();

  useEffect(() => {
    item && setItemId(item["id"]);
  }, [item]);

  useEffect(() => {
    itemId && brand.findOne(itemId);
  }, [itemId]);

  return (
    <ManagerDialog
      title={dialogTitle}
      onClose={onClose}
      size="md"
      content={
        <HandleForm
          {...props}
          item={brand.findOneData}
          stationTypes={stationTypes}
        />
      }
    />
  );
};

export default BrandManagerDialog;
