import appConfig from "config/app";
import { ApiVersion, getHttp } from "utils/http";
import { PlatformResponse } from "../entities/Platform";

export const getAllPlatforms = async (): Promise<PlatformResponse> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `compat/platform/all`
    );
    const data = await response.json();
    return { data } as PlatformResponse;
  } catch (ex: any) {
    console.error(`Error in getAllPlatforms | ${ex.message}`);
    return { data: [] } as PlatformResponse;
  }
};
