import { AlertColor } from "@mui/material";
import { showAlert } from "app/store/slices/global";
import { Alert } from "app/store/slices/global/state";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "./useAppDispatch";
import { alertMessage } from "app/i18n/types";

export const alert: Alert = {
  duration: 5000,
  audioOn: false,
  position: {
    vertical: "top",
    horizontal: "right",
  },
};

export interface Msg {
  title: string;
  message?: string;
  audioOn?: boolean;
}

interface UseAlert {
  success: () => void;
  error: () => void;
  successWithMsg: (msg: Msg) => void;
  errorWithMsg: (msg: Msg) => void;
  warningWithMsg: (msg: Msg) => void;
}

export const useAlert = (): UseAlert => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const showAppAlert = (alert: Alert) => dispatch(showAlert(alert));

  const defaultAlert = (props: Partial<Alert>) =>
    showAppAlert({ ...alert, ...props });

  const alertWithMsg = (msg: Msg, severity: AlertColor) => {
    const props: Partial<Alert> = {
      title: t(msg.title),
      message: msg.message ? t(msg.message) : undefined,
      audioOn: !msg.audioOn ? alert.audioOn : msg.audioOn,
    };
    showAppAlert({ ...alert, ...props, severity });
  };

  return {
    success: () =>
      defaultAlert({
        title: t(alertMessage.SUCCESSFUL_PROCESS),
        severity: "success",
      }),
    error: () =>
      defaultAlert({
        title: t(alertMessage.FAILED_PROCESS),
        severity: "error",
      }),
    successWithMsg: (msg: Msg) => alertWithMsg(msg, "success"),
    errorWithMsg: (msg: Msg) => alertWithMsg(msg, "error"),
    warningWithMsg: (msg: Msg) => alertWithMsg(msg, "warning"),
  };
};
