import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { commons, kitchenManager } from "app/i18n/types";
import { Kitchen } from "core/kitchens/entities/Kitchen";
import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Actions, KitchenModal } from "utils/modal";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";

interface Props {
  loading: boolean;
  kitchensFiltered: Kitchen[];
  selectedItems: KOSSelectedResult[];
  setModal: (modal: KitchenModal) => void;
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
}

const KitchenTable: FunctionComponent<Props> = (props) => {
  const {
    loading,
    kitchensFiltered,
    setModal,
    selectedItems,
    setSelectedItems,
  } = props;

  const { t } = useTranslation();

  const getActions = (item: KOSRowData) => (
    <>
      <IconButton
        color="warning"
        onClick={() =>
          setModal({
            open: true,
            action: Actions.edit,
            onClose: () => {},
            item: item as Kitchen,
          })
        }
      >
        <ModeEditOutlineOutlinedIcon />
      </IconButton>
      <IconButton
        color="info"
        onClick={() =>
          setModal({
            open: true,
            action: Actions.polygons,
            onClose: () => {},
            item: item as Kitchen,
          })
        }
      >
        <PinDropOutlinedIcon />
      </IconButton>
    </>
  );

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    return [
      { label: t(commons.ID), field: "kitchenId" },
      { label: t(commons.NAME), field: "name" },
      { label: t(commons.COUNTRY), align: "center", field: "country" },
      {
        label: t(commons.CITY),
        align: "center",
        text: (item) => item.city.name,
      },
      {
        label: t(commons.TYPE),
        align: "center",
        text: (item) => t(`kitchenManager.${item.type}`),
      },
      { label: t(commons.STATUS), align: "center", field: "status" },
      { label: "Tier", align: "center", field: "tier" },
      {
        label: t(commons.ACTION),
        align: "center",
        component: (item) => getActions(item),
      },
    ];
  }, []);

  return (
    <Box mt={2}>
      <KOSBaseTable
        columns={getHeader()}
        selectable={{
          resultHeader: ["id", "kitchenId"],
          items: selectedItems,
          onChange: setSelectedItems,
        }}
        pagination={{ enable: true }}
        rows={{
          data: kitchensFiltered,
          total: kitchensFiltered.length,
          loading: loading,
        }}
      />
    </Box>
  );
};

export default KitchenTable;
