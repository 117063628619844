import { storeManager } from "app/i18n/types";
import { Module } from "utils/module";

export enum LocationType {
  CP = "PCENTER",
  KITCHEN = "KITCHEN",
}

export const modules: Array<Module> = [
  {
    moduleId: "storeModule",
    moduleName: storeManager.TITLE,
    link: "/stores",
  },
];
